
var Auth = {
    data(){
      return{
        permissions: null,

      };
    },
    methods:{
        hasPermission(pattern){

            if(!this.permissions){
                var userStr = window.localStorage.getItem("user");
                var user = JSON.parse(userStr);
                this.permissions = user.permission;
                // console.log(this.permissions);
            }
            
            var isAll = this.permissions.find(p => p == "*");
            if(isAll){
                return true;
            }else{
                var result = this.permissions.find(p => p==pattern);
                if(result) {
                    return true;
                }else{
                    var patterns = pattern.split(":");
                    var match = this.permissions.find(p => p==patterns[0]);
                    // console.log(pattern, match, (match?true:false));

                    return match?true:false;
                }
            }
        },
        hasPermissions(pers, logic){  //表达式列表， logic: or/and

            if(!logic){
                logic = 'or';
            }
            if(!pers || pers.length == 0 ){
                return true;
            }

            if(pers.length == 1){
                return this.hasPermission(pers[0]);
            }

            for(let i=0; i<pers.length; i++){

                var result = this.hasPermission(pers[i]);
                
                if(logic == 'or' && result){
                    return true;
                }else if(logic == 'and' && !result){
                    return false;
                }
            }

            return true;

        },
        hasRole(){
            
        } 
    }
}

export default Auth;

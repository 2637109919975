import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { RouterTabRoutes } from 'vue-router-tab'
// 引入布局框架组件

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/admin',
    meta:{
      referrer:"origin"
    }
  },
  {
    path:'/admin',
    name: 'home',
    component: Home,
    redirect: '/admin/my-notice', 
    children:[
      ...RouterTabRoutes,
      {
        path: 'my-notice',
        name: 'my-notice',
        component: () => import(/* webpackChunkName: "mynotice" */ '../views/sys/MyNotice.vue'),
        meta:{
          title:'我的通知',
        }
      },
      {
        path: 'video',
        name: 'video',
        component: () => import(/* webpackChunkName: "video" */ '../views/sys/Video.vue'),
        meta:{
          title:'教学视频',
        }
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/sys/Dashboard.vue'),
        meta:{
          title:'友情链接',
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/sys/Contact.vue'),
        meta:{
          title:'联系方式',
        }
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "menu" */ '../views/sys/Menu.vue'),
        meta:{
          title:'菜单管理',
        }
      },
      {
        path: 'dept',
        name: 'dept',
        component: () => import(/* webpackChunkName: "menu" */ '../views/sys/Dept.vue'),
        meta:{
          title:'组织架构管理',
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/sys/User.vue'),
        meta:{
          title:'账户管理',
        }
      },
      {
        path: 'user/setting',
        name: 'userSetting',
        component: () => import(/* webpackChunkName: "userSetting" */ '../views/sys/UserSetting.vue'),
        meta:{
          title:'用户设置',
        }
      },
      {
        path: 'permission',
        name: 'permission',
        component: () => import(/* webpackChunkName: "permission" */ '../views/sys/Permission.vue'),
        meta:{
          title:'权限管理',
        }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '../views/sys/Role.vue'),
        meta:{
          title:'角色管理',
        }
      },
      {
        path: 'i18n',
        name: 'i18n',
        component: () => import(/* webpackChunkName: "i18n" */ '../views/sys/I18n.vue'),
        meta:{
          title:'语言设置',
        }
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/sys/Setting.vue'),
        meta:{
          title:'系统设置',
        }
      },
      {
        path: 'attachment',
        name: 'attachment',
        component: () => import(/* webpackChunkName: "attachment" */ '../views/sys/Attachment.vue'),
        meta:{
          title:'文件管理',
        }
      },
      {
        path: 'view-setting',
        name: 'view-setting',
        component: () => import(/* webpackChunkName: "view-setting" */ '../views/sys/ViewSetting.vue'),
        meta:{
          title:'视图管理',
        }
      },
      {
        path: 'loginhistory',
        name: 'loginhistory',
        component: () => import(/* webpackChunkName: "userSetting" */ '../views/sys/LoginHistory.vue'),
        meta:{
          title:'登录记录',
        }
      },
      {
        path: 'history',
        name: 'history',
        component: () => import(/* webpackChunkName: "history" */ '../views/sys/History.vue'),
        meta:{
          title:'历史记录',
        }
      },
      {
        path: 'message',
        name: 'message',
        component: () => import(/* webpackChunkName: "message" */ '../views/sys/Message.vue'),
        meta:{
          title:'消息管理',
        }
      },
      {
        path: 'chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "chat" */ '../views/sys/Chat.vue'),
        meta:{
          title:'我的消息',
          keepAlive: false,
        }
      },
      {
        path: 'workorder',
        name: 'workorder',
        component: () => import(/* webpackChunkName: "message" */ '../views/sys/WorkOrder.vue'),
        meta:{
          title:'工单记录',
        }
      },
      {
        path: 'workordermy',
        name: 'workordermy',
        component: () => import(/* webpackChunkName: "message" */ '../views/sys/WorkOrderMy.vue'),
        meta:{
          title:'我的工单',
        }
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import(/* webpackChunkName: "mynotice" */ '../views/sys/Notice.vue'),
        meta:{
          title:'通知管理',
        }
      },
      {
        path: 'notice-send',
        name: 'notice-send',
        component: () => import(/* webpackChunkName: "notice" */ '../views/sys/NoticeSend.vue'),
        meta:{
          title:'发送通知',
        }
      },
      {
        path: 'stock',
        name: 'stock',
        component: () => import(/* webpackChunkName: "stock" */ '../views/store/Stock.vue'),
        meta:{
          title:'货运管理',
        }
      },
      {
        path: 'stock/import',
        name: 'stockImport',
        component: () => import(/* webpackChunkName: "stockImport" */ '../views/store/StockImport.vue'),
        meta:{
          title:'导入资料',
        },
      },
      {
        path: 'stock/add',
        name: 'stockAdd',
        component: () => import(/* webpackChunkName: "stockAdd" */ '../views/store/StockAdd.vue'),
        meta:{
          title:'新增',
        },
      },
      {
        path: 'stock/inout',
        name: 'stockInOut',
        component: () => import(/* webpackChunkName: "stockImport" */ '../views/store/StockInOut.vue'),
        meta:{
          title:'入仓出仓',
        },
      },
      {
        path: 'stock/stock-detail',
        name: 'stockDetail',
        component: () => import(/* webpackChunkName: "stockImport" */ '../views/store/StockDetail.vue'),
        meta:{
          title:'预览详情',
        },
      },
      {
        path: 'stock/detail-edit',
        name: 'detail-edit',
        component: () => import(/* webpackChunkName: "stockImport" */ '../views/store/StockDetailEdit.vue'),
        meta:{
          title:'详情修改',
        },
      },
      {
        path: 'stock/stock-split',
        name: 'stockSplit',
        component: () => import(/* webpackChunkName: "stockImport" */ '../views/store/StockDetailSplit.vue'),
        meta:{
          title:'详情分拆',
        },
      },
      {
        path: 'stock/stock-buy',
        name: 'stockBuy',
        component: () => import(/* webpackChunkName: "stockImport" */ '../views/store/StockDetailBuy.vue'),
        meta:{
          title:'货物采购',
        },
      },
      {
        path: 'stock/qty-edit',
        name: 'stockQtyEdit',
        component: () => import(/* webpackChunkName: "stockImport" */ '../views/store/StockQtyEdit.vue'),
        meta:{
          title:'入库出库',
        },
      },
      {
        path: 'stock/match',
        name: 'stockMatch',
        component: () => import(/* webpackChunkName: "stockMatch" */ '../views/store/StockMatch.vue'),
        meta:{
          title:'库存匹配',
        },
      },
      {
        path: 'store',
        name: 'store',
        component: () => import(/* webpackChunkName: "store" */ '../views/store/Store.vue'),
        meta:{
          title:'仓库管理',
        },
      },
      {
        path: 'shop',
        name: 'shop',
        component: () => import(/* webpackChunkName: "shop" */ '../views/store/Shop.vue'),
        meta:{
          title:'店铺管理',
        }
      },
      {
        path: 'simple-product-name',
        name: 'simple-product-name',
        component: () => import(/* webpackChunkName: "simple-product-name" */ '../views/productName/SimpleProductName.vue'),
        meta:{
          title:'品名管理',
        }
      },
      {
        path: 'stock/check',
        name: 'stockCheck',
        component: () => import(/* webpackChunkName: "simple-product-name" */ '../views/store/StockCheck.vue'),
        meta:{
          title:'出货核对',
        }
      },
      {
        path: 'stock/checkCodeIn',
        name: 'checkCodeIn',
        component: () => import(/* webpackChunkName: "simple-product-name" */ '../views/store/CheckCodeInStore.vue'),
        meta:{
          title:'扫码入库',
        }
      },
      {
        path: 'stock/error',
        name: 'stockError',
        component: () => import(/* webpackChunkName: "simple-product-name" */ '../views/store/StockErrAdd.vue'),
        meta:{
          title:'异常通报',
        }
      },
      {
        path: 'doc',
        name: 'doc',
        component: () => import(/* webpackChunkName: "doc" */ '../views/sys/Document.vue'),
        meta:{
          title:'快速查件',
        }
      },
      {
        path: 'hqExport',
        name: 'hqExport',
        component: () => import(/* webpackChunkName: "doc" */ '../views/store/StockHqExport.vue'),
        meta:{
          title:'出货管理',
        }
      },
      {
        path: 'printFast',
        name: 'printFast',
        component: () => import(/* webpackChunkName: "doc" */ '../views/store/StockPrintFast.vue'),
        meta:{
          title:'快速打单',
        }
      },
      {
        path: 'qr-upp',
        name: 'QRUpp',
        component: () => import(/* webpackChunkName: "doc" */ '../views/store/StockQRUpp.vue'),
        meta:{
          title:'二维码上架',
        }
      },
      {
        path: 'storeDetail',
        name: 'storeDetail',
        component: () => import(/* webpackChunkName: "doc" */ '../views/store/StoreDetail.vue'),
        meta:{
          title:'库存管理',
        }
      },
      {
        path: 'expressOrder',
        name: 'expressOrder',
        component: () => import(/* webpackChunkName: "doc" */ '../views/store/ExpressOrder.vue'),
        meta:{
          title:'快递管理',
        }
      },
      {
        path: 'report',
        name: 'report',
        component: () => import(/* webpackChunkName: "doc" */ '../views/store/StockReport.vue'),
        meta:{
          title:'统计报表',
        }
      },
      {
        path: 'reportForSj',
        name: 'reportForSj',
        component: () => import(/* webpackChunkName: "doc" */ '../views/store/StockReportForSj.vue'),
        meta:{
          title:'出货统计',
        }
      },
      {
        path: 'code-check',
        name: 'code-check',
        component: () => import(/* webpackChunkName: "doc" */ '../views/store/StockCodeCheck.vue'),
        meta:{
          title:'条码校对',
        }
      },
      {
        path: 'check-hist',
        name: 'check-hist',
        component: () => import(/* webpackChunkName: "check-hist" */ '../views/store/CheckHist.vue'),
        meta:{
          title:'条码校对管理',
        }
      },
      {
        path: 'stock-add-store',
        name: 'stock-add-store',
        component: () => import(/* webpackChunkName: "stock-add-store" */ '../views/store/StockAddStore.vue'),
        meta:{
          title:'新增现货仓储',
        }
      },
      {
        path: 'my/wallet/:id',
        name: 'wallet',
        component: () => import(/* webpackChunkName: "wallet" */ '../views/sys/Wallet.vue'),
        meta:{
          title:'钱包',
        }
      },
      {
        path: 'wallet/list',
        name: 'walletList',
        component: () => import(/* webpackChunkName: "walletList" */ '../views/sys/WalletList.vue'),
        meta:{
          title:'会员钱包',
        }
      },
      {
        path: 'wallet/detail',
        name: 'walletDetail',
        component: () => import(/* webpackChunkName: "walletDetail" */ '../views/sys/WalletDetail.vue'),
        meta:{
          title:'消费记录',
        }
      },
      {
        path: 'vip',
        name: 'vip',
        component: () => import(/* webpackChunkName: "vip" */ '../views/sys/Vip.vue'),
        meta:{
          title:'会员续费',
        }
      },
      {
        path: 'vip-setting',
        name: 'vip-setting',
        component: () => import(/* webpackChunkName: "vip" */ '../views/sys/VipSetting.vue'),
        meta:{
          title:'会员设置',
        }
      },
      {
        path: 'vip-order',
        name: 'vip-order',
        component: () => import(/* webpackChunkName: "vip" */ '../views/sys/VipOrder.vue'),
        meta:{
          title:'会员订单',
        }
      },
      {
        path: 'offer',
        name: 'offer',
        component: () => import(/* webpackChunkName: "offer" */ '../views/sys/Offer.vue'),
        meta:{
          title:'报价设置',
        }
      },
      {
        path: 'my-offer',
        name: 'my-offer',
        component: () => import(/* webpackChunkName: "myOffer" */ '../views/sys/OfferMy.vue'),
        meta:{
          title:'我的报价',
        }
      },
      {
        path: 'freight',
        name: 'freight',
        component: () => import(/* webpackChunkName: "offer" */ '../views/store/FinanceTransit.vue'),
        meta:{
          title:'运费核算',
        }
      },
      {
        path: 'proxy',
        name: 'proxy',
        component: () => import(/* webpackChunkName: "offer" */ '../views/store/FinanceProxy.vue'),
        meta:{
          title:'回款核算',
        }
      },
      {
        path: 'lipei',
        name: 'lipei',
        component: () => import(/* webpackChunkName: "offer" */ '../views/store/FinanceLipei.vue'),
        meta:{
          title:'理赔核算',
        }
      },
      {
        path: 'kesu',
        name: 'kesu',
        component: () => import(/* webpackChunkName: "offer" */ '../views/store/FinanceKesu.vue'),
        meta:{
          title:'客诉件结算',
        }
      },
      {
        path: 'declare',
        name: 'declare',
        component: () => import(/* webpackChunkName: "declare" */ '../views/declare/Declare.vue'),
        meta:{
          title:'报关资料',
        }
      },
      {
        path: 'purchase',
        name: 'purchase',
        component: () => import(/* webpackChunkName: "purchase" */ '../views/purchase/Purchase.vue'),
        meta:{
          title:'采购链接',
        }
      },
      {
        path: 'category',
        name: 'category',
        component: () => import(/* webpackChunkName: "purchase" */ '../views/category/Category.vue'),
        meta:{
          title:'品类管理',
        }
      },
      {
        path: 'stockDetailTongji',
        name: 'stockDetailTongji',
        component: () => import(/* webpackChunkName: "purchase" */ '../views/store/StockDetailTongji.vue'),
        meta:{
          title:'库存管理',
        }
      },
      {
        path: 'company',
        name: 'company',
        component: () => import(/* webpackChunkName: "purchase" */ '../views/company/Company.vue'),
        meta:{
          title:'开户公司',
        }
      },
      {
        path: 'point-detail',
        name: 'point-detail',
        component: () => import(/* webpackChunkName: "purchase" */ '../views/sys/PointDetail.vue'),
        meta:{
          title:'积分记录',
        }
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import(/* webpackChunkName: "purchase" */ '../views/sys/Coupon.vue'),
        meta:{
          title:'优惠券',
        }
      },
      {
        path: 'site',
        name: 'site',
        component: () => import(/* webpackChunkName: "purchase" */ '../views/express/Site.vue'),
        meta:{
          title:'集运站点',
        }
      },
      {
        path: 'site-address',
        name: 'site-address',
        component: () => import(/* webpackChunkName: "purchase" */ '../views/express/SiteAddress.vue'),
        meta:{
          title:'集运地址',
        }
      },
      {
        path: 'transit/import',
        name: 'transit/import',
        component: () => import(/* webpackChunkName: "stockImport" */ '../views/sys/TransitImport.vue'),
        meta:{
          title:'导入运费',
        },
      },
      {
        path: 'exp-company',
        name: 'exp-company',
        component: () => import(/* webpackChunkName: "printSetting" */ '../views/expCompanyPage/ExpCompanyPage.vue'),
        meta:{
          title:'配送方式',
        },
      },
      {
        path: 'stock-order',
        name: 'stock-order',
        component: () => import(/* webpackChunkName: "printSetting" */ '../views/order/Order.vue'),
        meta:{
          title:'订单管理',
        },
      },
      {
        path:'*',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
      },
    ]
  },
  { 
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  { 
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Index1.vue')
  },
  { 
    path: '/reg',
    name: 'reg',
    component: () => import(/* webpackChunkName: "Reg" */ '../views/Reg.vue')
  },
  {
    path:'*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
/*
router.beforeEach((to, from, next) => {
  console.log(to, from , next);
  if (from.name === 'specGroup') {
    next(vm => {
      debugger;
      console.log("12312");
      vm.$route.meta.keepAlive = false
    });
  }else{
    next()
  }
})
*/

export default router

<template>
  <div>
    <el-input v-model="option.data" placeholder="搜索" @input="changeOptionEvent">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'FilterInput',
  props: {
    params: Object
  },
  data () {
    return {
      column: null,
      option: null
    }
  },
  created () {
    // filters 可以配置多个，实际只用一个就可以满足需求了
    const { column } = this.params
    const option = column.filters[0]
    this.column = column
    this.option = option
  },
  methods: {
    changeOptionEvent () {
      const { params, option } = this
      const { $panel } = params
      const checked = !!option.data
      $panel.changeOption(null, checked, option)
    }
  }
}
</script>
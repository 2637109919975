<template>
<div class="notice-wrap">
    <el-image
      style="width: 800px; height: 500px"
      :src="url">
    </el-image>
    <div style="text-align:center;">
        <el-button type="primary" size="mini" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>
</div>
</template>

<script>
export default {
  name: 'NoticeView',
  data(){
    return{
        url:this.$kit.api.imgView+66,
    }
  }, 
  methods:{
  }
}
</script>

</style>

<template>
<div>
    <div v-if="notices && notices.length > 0" class="table-wrap">
      <vxe-grid ref="dataTable" 
          style="width: 100%;"
          max-height="400"
          resizable border
          show-overflow highlight-hover-row
          highlight-current-row highlight-hover-column highlight-current-column
          :data="notices"
          :columns="columns"
          @cell-click="clickRow"
          :toolbar-config="tableToolbar"
        >
        <template v-slot:toolbar_buttons>
          <el-button style="margin-left:20px" type="primary" @click="allRead" size="mini">标为已读</el-button>
        </template>
        </vxe-grid>
    </div>
    <div v-if="errors && errors.length > 0" class="table-wrap">
        <vxe-grid ref="dataTable1" 
          style="width: 100%;"
          max-height="400"
          resizable border
          show-overflow highlight-hover-row
          highlight-current-row highlight-hover-column highlight-current-column
          :data="errors"
          :columns="columns1"
        >
        </vxe-grid>
    </div>

        <el-dialog :title="msg('查看通知')" :visible.sync="dialogViewVisible" :show-close="false"  
          :close-on-click-modal="false"
          :fullscreen="isMinScreen" :append-to-body="true" @open="openView">
            <notice-view ref="noticeViewRef" @close-dg="dialogViewVisible=false"/>
        </el-dialog>
</div>
</template>

<script>
import NoticeView from "./NoticeView.vue";
export default {
  name: 'WarnMessage',
  components: {
      NoticeView: NoticeView
  },
  data(){
    return{
        dialogViewVisible:false,
        currNotice:null,
        tableToolbar: {
          slots: {
            buttons: 'toolbar_buttons'
          }
        },
        columns:[
          {
            title: '通知信息',
            children: [
              { type: 'checkbox', minWidth: 40 },
              {field: "id", title: this.msg("ID"), minWidth: 80, sortable: true},
              {field: 'title', title: this.msg("标题"), minWidth: 100, sortable: true },
              {field: "overview", title: this.msg("内容"), minWidth: 200, sortable: true},
              // {field: "fullname", title: this.msg("发送人"), minWidth: 80, sortable: true},
              {field: "created", title: this.msg("发送时间"), minWidth: 100, sortable: true},
              {field: "status", title: this.msg("状态"), minWidth: 100, sortable: true,
                slots: {
                    // 使用渲染函数
                    default: ({ row }, h) => {
                      return [
                        h('span', {
                          style: {
                            color: 'green',
                          },
                        }, row.status==1?"已读":"未读")
                      ]
                    }
                  }},
              {
                  title: '处理',
                  showOverflow: true,
                  minWidth: 80,
                  slots: {
                    // 使用渲染函数
                    default: ({ row }, h) => {
                      return [
                        h('button', {
                          style: {
                            color: 'red',
                          },
                          on: {
                            click: () => this.editRead(row)
                          }
                        }, "标为已读")
                      ]
                    }
                  }
                },
            ]
          },
        ],
        columns1:[
          {
            title: '异常信息',
            children: [
              { type: 'seq', width: 20 },
              {field: 'code', title: this.msg("订单编号"), minWidth: 120, sortable: true },
              {field: "errCode", title: this.msg("异常类型"), minWidth: 100, sortable: true},
              {field: "errMsg", title: this.msg("异常说明"), minWidth: 140, sortable: true},
              {field: "errPropose", title: this.msg("处理建议"), minWidth: 100, sortable: true},
              {field: "errTime", title: this.msg("创建时间"), minWidth: 100, sortable: true},
              {
                  title: '处理',
                  showOverflow: true,
                  minWidth: 80,
                  slots: {
                    // 使用渲染函数
                    default: ({ row }, h) => {
                      return [
                        h('button', {
                          style: {
                            color: 'red',
                          },
                          on: {
                            click: () => this.errHandle(row)
                          }
                        }, "立即处理")
                      ]
                    }
                  }
                },
            ]
          },
        ]
    }
  }, 
  props: {
    notices: Array,
    errors: Array,
  },
  computed:{
      isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  mounted: function(){
  },
  methods:{
    allRead(){
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
          this.$message(this.msg('请选中一条数据进行操作'));
          return;
      }
      let ids = selectes.map(e => e.id).join(",");
      this.$axios.post(this.$kit.api.notice.updateStatus+"?id="+ids)
            .then((result) => {
              this.notices.forEach(e => {
                if(ids.indexOf(e.id) > -1){
                  e.status = 1;
                }
              });
            });
    },
    editRead(row){
      row.status = 1;
      this.$axios.post(this.$kit.api.notice.updateStatus+"?id="+row.id)
            .then((result) => {
              // let index = this.$refs.dataTable.getRowIndex(row);
              // this.notices.splice(index,1);
              // if(this.notices.length <= 0 && this.errors.length <= 0){
              //   this.$emit("close-dg");
              // }
            });
    },
    selectRow(row, column, event){
        this.currNotice = row;
        row.status = 1;
        this.$axios.post(this.$kit.api.notice.updateStatus+"?id="+row.id)
            .then((result) => {
              this.dialogViewVisible = true;
            });
    },
    clickRow({row,rowIndex, $event, column, columnIndex}){
      if(columnIndex <= 5 && columnIndex > 0){
        this.currNotice = row;
        this.currContent = row.content;
        row.status = 1;
        this.$axios.post(this.$kit.api.notice.updateStatus+"?id="+row.id)
            .then((result) => {
              this.dialogViewVisible = true;
            });
      }
      },
    openView(){
        this.$nextTick(() => {
            this.$refs.noticeViewRef.init(this.currNotice.id);
        });
    },
    closeViewDialog(type){  //编辑弹窗关闭后的回调事件
        this.dialogViewVisible = false;
    },
    errHandle(row){
      var _this = this;
      if(row.errCode.indexOf('MD面单异常') > -1 || row.errCode.indexOf('WD没有面单') > -1 || row.errCode.indexOf('GQ面单过期') > -1){
          _this.$emit("close-dg");
          this.$tabs.open ("/admin/stock");
      }else{
          this.$confirm(this.msg(row.errCode+'是否已确认处理完毕?'), this.msg('提示'), {
                confirmButtonText: this.msg('是'),
                cancelButtonText: this.msg('否'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.stock.updateError+"?id="+row.id,)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('处理完成'));
                    if(result.status){
                      _this.errors.splice(row.index,1);
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
      }
    }
  }
}
</script>
<style scoped lang="scss">
.table-wrap{
max-height:400px;padding: 0px;
margin-top: 10px;min-height:200px;
}
</style>

<template>
<div class="xcontainer" :style="{background: theme.background}">
  <header class="xheader">
    <div :class="['xcompany-info', (aside && showMenu)?'company-aside':'company-top', isCollapse?'company-min':'']">
      <div class="xlogo" v-popover:userCardPopover> 
        <div :class="isCollapse? 'logo-min-wrap' : 'logo-wrap'">
          <img v-if="user.companyLogo > 0"  :src="this.imgUrl+user.companyLogo+'&w=128'" alt="logo" >
          <img v-else-if="companyInfo && companyInfo['logo']"  :src="this.imgUrl+companyInfo['logo']+'&w=128'" alt="logo" >
        </div>  
        <el-collapse-transition>
          <div v-show="!isCollapse" :style="{color: (aside && showMenu)?theme.menuTextColor:theme.color}">{{user.companyTitle?user.companyTitle:'順運寶'}}</div>
        </el-collapse-transition>
      </div>
    </div>
    <div :class="['head-bar1', aside?'head-bar1-aside': 'header-bar-top', isMinScreen?'head-bar1-min':'']">
      <div class="header-left" style="white-space:nowrap;">
        <span class="header-title" :style="{color: theme.color}">
          {{msg('欢迎登录')}} 
          <span v-if="user">{{user.fullname}} 
            <span style="font-size: 14px;">
              <span>【{{dicts.vipTypes[user.vip].label}}】</span>
              <span v-if="user.vipBalanceDay>=0&&user.vipBalanceDay<=10">
                <span v-if="user.vipBalanceDay>0&&user.vipBalanceDay<=10">剩</span>
                {{user.vipDayTxt}}
              </span>
            </span>
          </span>
          <el-link @click="gotoVip()" type="danger" style="color:red; font-weight: bold; margin-left:5px; margin-top:3px;">{{msg('续费')}}</el-link>
          <el-link :underline="false" @click="showVipHelp()" style="margin-left:3px;">
            <!-- <i class="iconfont icon-tishi1 font18" ></i> -->
            <icon-svg icon-class="icon-tishi2" style="margin-top:7px;" font-size="20px;"/>
          </el-link>
        </span>
      </div>
      <div class="xtool" :style="{background: theme.headBackground}">
          <el-badge :value="totalMessage" class="msg-badge"> 
            <span :style="{color: theme.toolColor}" class="el-icon-chat-dot-square"  @click="toCharting()"></span>
          </el-badge>
          <el-badge :value="warnTotal" class="msg-badge"> 
            <span :style="{color: theme.toolColor}" class="el-icon-bell" @click="openNoticeRoute()"></span>
          </el-badge>
          <el-dropdown @command="switchSkin" class="switchSkin">
            <div :style="{color: theme.toolColor}" class="iconfont icon-pifu">
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="my-dd-item iconfont" divided icon="icon-buju" command="layout-aside">{{msg('左右布局')}}</el-dropdown-item>
              <el-dropdown-item class="my-dd-item iconfont" divided icon="icon--morenbuju" command="layout-top">{{msg('上下布局')}}</el-dropdown-item>
              <el-dropdown-item class="my-dd-item iconfont" divided icon="icon-expand" command="menu-min">{{msg('菜单切换')}}</el-dropdown-item>
              <el-dropdown-item class="my-dd-item iconfont" divided :icon="showMenu?'icon-yincangdaan':'icon-chakan-'" command="showMenu">{{showMenuLabel}}</el-dropdown-item>
              <el-dropdown-item class="my-dd-item iconfont"  v-for="theme in themes" :key="theme.name" divided
                 :command="theme.name">
                <i class="icon-pifu" :style="{color: theme.menuBackground}"></i>
                {{msg(theme.name)}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="switchLang" class="switch-lang">
            <div :style="{color: theme.toolColor}" :class="['iconfont', langIcon?langIcon:'zh-cn']">
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="my-dd-item iconfont" divided  icon="icon-yuyan-zhong" command="zh-cn">{{msg('简体中文')}}</el-dropdown-item>
              <el-dropdown-item class="my-dd-item iconfont" divided  icon="icon-lang-txt-zh-tw" command="zh-tw">{{msg('繁体中文')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!--
          <el-link @click="gotoVip()" type="danger" style="color:red; font-weight: bold; margin-right:10px;">{{msg('会员')}}</el-link>
          -->
      </div>
    </div>
  </header>
  <div :class="['xcenter', aside?'xcenter-aside': 'xcenter-top', isCollapse?'xcenter-min':'', showMenu?'':'xcenter-full']">
    <aside v-if="refreshMenu && showMenu" class="xmenu"
      :style="{color: theme.menuBackground}" 
    >
      <el-scrollbar style="width:100%; height:calc(100vh - 48px);"  ref="menuScroll">
        <el-menu 
          :collapse="aside ? isCollapse: false"
          :background-color="theme.menuBackground"
          :text-color="theme.menuTextColor" 
          :active-text-color="theme.menuTextActiveColor" 
          @select="selectMenuItem"
          :mode="aside? 'vertical' : 'horizontal'"
          id="menu"
        > 
          <template v-for="item in menus">
            <el-menu-item v-if="!item.children || item.children.length==0" :index="item.id+''" :route="{name: item.url}" :key="item.id">
              <i v-if="item.clazz" style="margin-right:8px;" :class="['iconfont', item.clazz]"></i>
              <transition name="el-fade-in-linear">
                <span v-show="!aside || (aside && !isCollapse)" >{{item.name}}</span>
              </transition>
            </el-menu-item>
            <el-submenu v-else :index="item.id+''" :key="item.id+''">
              <template slot="title">
                <i v-if="item.clazz" style="margin-right:8px;" :class="['sub-icon','iconfont', item.clazz]"></i> 
                <transition name="el-fade-in-linear">
                  <span v-show="!aside || (aside && !isCollapse)">{{item.name}}</span>
                </transition>
              </template> 
              <el-menu-item :index="sub.id+''" v-for="sub in item.children" :route="{name: item.url}" :key="sub.id">
                <i v-if="sub.clazz" style="margin-right:8px;" :class="['iconfont', sub.clazz]"></i><span>{{sub.name}}</span>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </el-scrollbar>
    </aside>
    <article class="xmain">
      <transition name="el-fade-in-linear">
        <keep-alive>
          <router-tab ref="routerTab"/>
        </keep-alive>
      </transition>
    </article>
  </div>
  <el-popover
    popper-class="user-card-pop"
    style="padding:0px;"
    ref="userCardPopover"
    placement="bottom"
    width="180"
    trigger="click"
    v-model="userCardVisiable"
  >
    <div class="user-card">
      <div class="top-bg">
        <div class="user-photo-wrap">
          <!-- <img src="@/assets/img/user1.png" class="image"> -->
          <img v-if="user.photo || user.photoId" :src="imgUrl+(user.photo || user.photoId)" >
          <div v-else>{{user.fullname.substring(0,1).toUpperCase()}}</div>
        </div>

      </div>
      <div class="card-fullname">{{user.fullname}}</div>
      <div class="user-tool">
        <el-link :underline="false"  @click="clickInfo()">
          <i class="iconfont icon-zhanghuxinxi font16"></i>
          {{msg('个人信息')}}
        </el-link>
        <el-link :underline="false" class="odd" @click="userSetting()">
          <i class="iconfont icon-yonghushezhi font16"></i>
          {{msg('个人设置')}}
        </el-link>
        <el-link :underline="false"  @click="uploadPhoto()">
          <i class="iconfont icon-shangchuantouxiang font16" ></i>
          {{msg('上传头像')}}
        </el-link>
        <el-link :underline="false" class="odd" @click="updatePwd()">
          <i class="iconfont icon-jinyong font16" ></i>
          {{msg('修改密码')}}
        </el-link>
        <el-link :underline="false" @click="loginHistory()">
          <i class="iconfont icon-xiaoxijilu font16" ></i>
          {{msg('登录记录')}}
        </el-link>
        <el-link :underline="false" class="odd" @click="logout">
          <i class="iconfont icon-icon-tuichudenglu font16" ></i>
          {{msg('退出登录')}}
        </el-link>
      </div>
    </div>
  </el-popover>
  <div class="temp" ref="temp">

  </div>
 <el-dialog :title="dgTitle" :close-on-click-modal="false"  :fullscreen="isCollapse"
      :visible.sync="dgVisible" width="1000px"  @opened="openedEdit"
      >
          <edit :type="0" @close-dg="closeEditDialog" ref="editDg"/>
  </el-dialog>

  <el-dialog :title="msg('通知')" :fullscreen="isCollapse" :visible.sync="dialogWarnVisible" width="800px" 
   :append-to-body="true" :destroy-on-close="true">
    <warn v-on:close-dg="closeWainDialog" ref="warnForm" :notices="notices" :errors="errors" />
  </el-dialog>

  <el-dialog :title="msg('查看通知')" :fullscreen="isCollapse" :visible.sync="noticeViewVisible"  @open="openViewNotice()"
      width="800px"  :append-to-body="true" :destroy-on-close="true">
    <notice-view v-on:close-dg="noticeViewVisible=false" ref="noticeViewRef"/>
  </el-dialog>

  <el-dialog :title="msg('修改密码')" :fullscreen="isCollapse" :visible.sync="dialogPwd" width="400px" @open="opendChangePwd()" 
   :close-on-click-modal="false" :append-to-body="true" :destroy-on-close="true">
    <pwd v-on:close-dg="closePwdDialog" ref="pwdRef" />
  </el-dialog>

  <el-dialog :title="msg('帮助')" :fullscreen="isCollapse" :visible.sync="vipImgVisible"
      width="850px"  :append-to-body="true" :destroy-on-close="true">
    <vip-img-view v-on:close-dg="vipImgVisible=false" ref="vipImgRef"/>
  </el-dialog>

</div>
</template>


<script>
import Edit from "./sys/UserDetail.vue";
import Pwd from "./sys/UserPwd.vue";
import Warn from "./sys/WarnMessage.vue";
import NoticeView from "./sys/NoticeView.vue";
import VipImgView from "./sys/VipImgView.vue";
import axios from "axios";
// @ is an alias to /src
export default {
  name: 'home',
  components: {
    Edit: Edit,
    Warn:Warn,
    Pwd:Pwd,
    NoticeView:NoticeView,
    VipImgView:VipImgView
  },
  created(){

    // this.$nextTick(()=>{
    //     var routerTab = this.$refs.routerTab;
    //     console.log(routerTab);
    //     window.routerTab = routerTab;
        
    //   });
    

    var _this = this;
    this.user  = JSON.parse(localStorage.getItem("user"));
    let res = this.doRedirect();
    if(!res){
      return;
    }
    this.$store.commit('loginId', this.user.id);
    this.$store.commit('admin', this.user.admin);

    // else if(rdUrl == 'account') {
    //   this.$router.push("/admin/user");
    // }

    this.$store.commit('docHeight', window.docHeight);
    if(window.docWidth<600){
      this.isCollapse = true;
      this.$store.commit('isMinScreen', true);
      this.$store.commit('isQueryExpend', true);
      this.isMinScreen = true;
    }else{
      this.$store.commit('isMinScreen', false);
      this.$store.commit('isQueryExpend', false);
      this.isMinScreen = false;
    }

    window.onresize = function(){
      var width = document.documentElement.clientWidth;
      _this.$store.commit('docHeight', document.documentElement.clientHeight);
      _this.$store.commit('docWidth', width);
      if(width<600){
        _this.isCollapse = true;
        _this.$store.commit('isMinScreen', true);
        _this.isMinScreen = true;
      } else {
        _this.isCollapse = false;
        _this.$store.commit('isMinScreen', false);
        _this.isMinScreen = false;
      }
    };

    this.axios.get(this.$kit.api.menu.curr).then((result) => {
        if(result.status){
          var resultMenus = result.data;

          var storeMenus = {};
          resultMenus.forEach(m => {
            storeMenus[m.url] = {
              title: m.name,
              icon: 'iconfont '+m.clazz
            }
          });
          this.$store.commit('activeMenus', storeMenus)

          var ms = this.$kit.buildTree(resultMenus, true);
          this.menus = ms;
        }else{
          _this.$message(result.msg);
        }
      }).catch(function (error) {
        console.error(error);
    });

    this.langs["zh-cn"] = "icon-yuyan-zhong";
    this.langs["zh-tw"] = "icon-lang-txt-zh-tw";
    this.langs["en"] = "icon-yuyan-ying";

    var lang = localStorage.getItem("lang") || 'zh-cn';
    this.langIcon = this.langs[lang];

    // window.document.title = this.user.webTitle?this.user.webTitle:"title";
    window.document.title = "title";

    var company = this.$store.state.companyInfo;
    if(company){
      this.companyInfo = company;
    }else{
      this.axios.get(this.$kit.api.companyInfo).then((result) => {
        //console.log(result); 
        if(result.status){
          this.companyInfo=result.data;
          this.$store.commit('companyInfo', this.companyInfo);
        }
        
      }).catch(function (error) {
        console.error(error);
      });
    }

    //设置布局
    let layout = localStorage.getItem("layout");
    if(layout){
      this.aside = layout == 'aside'?true: false;
    }

    //设置菜单
    let sm = localStorage.getItem("showMenu");
    if(sm !== null){
      if(sm == 'false'){
        this.showMenu = false;
        this.showMenuLabel = this.msg('显示菜单');
      }
      else{
        this.showMenu = true;
        this.showMenuLabel = this.msg("隐藏菜单");
      }
    }

    this.showVipTip();

    this.showWalletTip();

    // setTimeout(() => {
    //   this.getWarnNotice();
    //   this.getTotalMessage();
    // }, 30000);

    //通知提醒
    setInterval(() => {
      this.getWarnNotice();
      this.getTotalMessage();
      this.getWorkOrderMessage();
    }, 300000);

    setInterval(() => {
      this.showWalletTip();
    }, 360000);

  },
  mounted(){
    //监听菜单滚动
    this.scollMenu();
  },
  data(){
    return{
      menus: [],  //右侧显示菜单
      user: { //登录信息
        fullname: null
      },
      noticeViewVisible: false, //查看通知
      vipImgVisible:false,
      noticeIds: [], //当前查看的通知
      currNoticeIdx: null,
      noticeVisible: false,
      isCollapse: false,  //右侧菜单是否展开
      title: "欢迎",  //顶部标题
      isShowAside: true,  //是否显示右侧菜单
      langIcon: null, //右上角的语言切换图标
      langs: {},    //本系统自持的语言种类
      imgUrl: this.$kit.api.imgView,  //图片（查看，下载）的url
      companyInfo: {},  //公司信息

      dialogWarnVisible:false,  //查看查看通知的弹窗是否显示
      notices:[],     //通知列表
      errors:[],//异常列表
      warnTotal:0,  //通知的总记录数

      isMinScreen: false,

      dialogPwd:false,  //修改密码的弹窗是否显示

      userCardVisiable: false,  //点击logo的下拉菜单是否显示
      dgVisible:false,  //查看用户详细信息的弹窗是否显示
      dgTitle:null,   //查看用户的弹窗标题

      showMenu: true,     //是否显示菜单
      showMenuLabel: '隐藏菜单',  //显示菜单按钮的文本
      aside: true,  //菜单是否在右侧：false: 菜单显示在顶部
      refreshMenu: true,  //v-if可以重新构建dom

      totalMessage: 0, //消息总数

      themes: [  //系统的主题色调
        { 
          name:"主题-默认", 
          menuBackground: "#545c64", 
          menuTextColor: "#ffffff",
          menuTextActiveColor: "#ffd04b", 
          background:'#EDF0F5', 
          color: "#5a5a5a", 
          headBackground: "white", 
          toolColor: "#8a8a8a",
        },
      ],
      theme: {  //当前显示的主题
          name:"主题-默认",   //主题名字
          menuBackground: "#545c64",  //菜单背景色
          menuTextColor: "#ffffff",   //菜单文字颜色
          menuTextActiveColor: "#ffd04b",  //菜单激活后的颜色
          background:'#EDF0F5',   //背景色
          color: "#5a5a5a",       //文字颜色
          headBackground: "white",  //顶部背景色
          toolColor: "#8a8a8a",   //工具栏颜色
        },
        
      }
  }, 
  computed:{
  },
  methods: {
    asideCollapse: function(){  //显示或者隐藏菜单朗
      //console.log(this.isCollapse);
      this.isCollapse = !this.isCollapse;
    },
    asideShow: function(){  //当菜单在左侧显示的时候，菜单显示还是隐藏
      //console.log(this.isCollapse);
      this.isShowAside = !this.isShowAside;
    },
    loginHistory: function(){
      this.$tabs.open("/admin/loginhistory");
    },
    logout(){  //退出登录
      this.userCardVisiable = false;

      let tokens = localStorage.getItem("rtokens");
      let token = "";
      if(tokens){
        let tokenArr = JSON.parse(tokens);
        token = tokenArr.pop();
        localStorage.setItem("token", token);

        if(tokenArr.length==0){
          localStorage.removeItem("rtokens");
        }else{
          localStorage.setItem("rtokens", JSON.stringify(tokenArr));
        }
      }

      this.axios.get(this.$kit.api.logout+"?token="+token)
        .then(result => {
          if(result.status){
              if(result.data){
                window.location.href=window.location.href+"?refresh=true";
              }else{
                localStorage.removeItem("rtokens");
                localStorage.removeItem("token");
                this.$router.push("/login");
              }
          }
        });
    },
    switchLang: function(command){  //切换系统语言
      this.langIcon = this.langs[command];
      localStorage.setItem("lang", command);
      location.replace(location.href);
    },
    selectMenuItem: function(index, indexPath){  //点击菜单
      //console.log(index, indexPath);
      var menu = this.findMenu(index*1, this.menus);
      //console.log(menu)
      if(menu){
        if(menu.url.indexOf('/admin/my/wallet')>-1){
          this.$router.push(menu.url+"/"+this.user.id);
        }else{
          this.$router.push(menu.url);
        }

      }
    },
    findMenu: function(id, menus){  //查找菜单
      var m = null;
      for(var i=0; i<menus.length; i++){
        var menu = menus[i];
        if(menu.id == id){
          m = menu; 
          break;
        }
        else if(menu.children && menu.children.length>0){
          m = this.findMenu(id, menu.children);
          if(m!=null) 
            break;
        }
      } 
      return m;
    },

    //设置主布局
    setLayout(layout){  //设置布局
      if(layout == 'aside'){
        this.aside = true;
      }else{
        this.aside = false;
      }

      localStorage.setItem("layout", layout);
      
      this.refreshMenu = false;
      setTimeout(()=>{
        this.refreshMenu = true;
        setTimeout(()=>{
          this.scollMenu();
        }, 100);
      }, 100)
    },
    switchSkin(command){
      if(command == 'layout-aside'){  //设置菜单左侧显示
        this.setLayout('aside'); 
      }else if(command == 'layout-top'){  //设置菜单顶部显示
        this.setLayout('top');
        // this.$store.commit('docHeight', document.documentElement.clientHeight - 65);
      }else if(command == 'showMenu'){  //设置菜单显示还是隐藏
        this.showMenu = !this.showMenu;
        this.showMenuLabel = this.showMenu?this.msg('隐藏菜单'):this.msg('显示菜单');
        localStorage.setItem("showMenu", this.showMenu);
      }else if(command == 'menu-min'){  //设置菜单显示还是隐藏
        this.isCollapse = !this.isCollapse;
      }
    },
    scollMenu(){  //菜单在顶部显示的时候，滚动鼠标，菜单左右滑动
      //绑定菜单滚动事件，使其在菜单横向排版的时候，支持鼠标滚动，
      //竖向排版的时候，取消事件绑定

      var menuScroll = this.$refs.menuScroll;
      this.$nextTick(() => {
        menuScroll.$el.removeEventListener("wheel", this.menuScrollEvent, false);
        menuScroll.$el.querySelector(".el-scrollbar__wrap").style.cssText ="margin-bottom: -19px; margin-right: -19px;overflow-x:hidden"

        if(!this.aside){
          menuScroll.$el.addEventListener("wheel", this.menuScrollEvent, { passive: false });
          // menuScroll.$el.onwheel = this.menuScrollEvent;
        }
        
      });
    },
    menuScrollEvent(event){  //菜单滚动事件，使其在菜单横向排版的时候，支持鼠标滚动。
      var menuScroll = this.$refs.menuScroll;
      var wrap = menuScroll.$refs['wrap'];
      
      //禁止事件默认行为（此处禁止鼠标滚轮行为关联到"屏幕滚动条上下移动"行为） 
      event.preventDefault();  

      if(menuScroll.sizeWidth){
        //设置鼠标滚轮滚动时屏幕滚动条的移动步长  
        var step = 50;  
        if(event.deltaY < 0){  
            //向上滚动鼠标滚轮，屏幕滚动条左移  
            wrap.scrollLeft -= step;  
        } else { 
            //向下滚动鼠标滚轮，屏幕滚动条右移  
            wrap.scrollLeft += step;  
        } 
      }
    },
    clickInfo(){  //显示用户详细信息
      this.dgTitle = this.msg("查看用户")+":"+this.user.fullname;
      this.dgVisible = true;
      this.userCardVisiable = false;
    },
    updatePwd(){    //修改用户密码
      this.dialogPwd = true;
    },
    closePwdDialog(){  //关闭修改密码弹窗
      this.dialogPwd = false;
    },
    opendChangePwd(){  //打开修改密码弹窗的回调函数
      this.$nextTick(() => {
        this.$refs.pwdRef.initForm();
      });
    },
    openedEdit(){ //打开修改个人信息的回调函数
        this.$nextTick(() => {
            this.$refs.editDg.initForm(this.user.id);
        });
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
        if(type){
            this.list();
        }
        this.dgVisible = false;
    },
    showVipHelp(){
      this.vipImgVisible = true;
    },
    uploadPhoto(){  //点击上传头像
      var _this = this;
      var inputFile = document.createElement("input");
      inputFile.type = "file";
      inputFile.style.display="none";
      inputFile.accept = "image/gif, image/jpeg, image/png";
      inputFile.onchange =  function(e){
        e.preventDefault();
        let formData = new FormData();
        formData.append('file',e.target.files[0]);

        _this.axios.post(_this.$kit.api.upload+"?auth=1", 
            formData, 
            {headers:{'Content-Type':'multipart/form-data'}}
          ).then((result) => {
            if(result.status){
              _this.user['photoId'] = result.data;
              _this.axios.post(_this.$kit.api.user.updatePhoto, {id:_this.user.id, photoId: result.data})
                .then((rs) => {
                  _this.$message(rs.msg || _this.msg('更新成功'));
                }).catch(function (error) {
                  console.error(error);
                });
            }
          }).catch(function (error) {
            console.error(error);
          });
          this.$refs.temp.removeChild(e.target);
      };

      this.$refs.temp.appendChild(inputFile);
      inputFile.click();

    },
    openNoticeRoute(){
      this.getWarnNotice(1);
      this.$tabs.open("/admin/my-notice");
    },
    getWarnNotice(type){  //获取通知总数

      var _this = this;
      axios.request({
            url: this.$kit.api.notice.warnList, 
            method: 'post',
            headers: {"Content-Type":"application/json; charset=utf-8",
                    "X-Requested-With":"XMLHttpRequest",
                },
            withCredentials: true,
        }).then((result) => {
          if(result.status){
            
            let notice = [];
            let error = [];
            let shows = [];
            let total = 0;

            if(result.data != null && result.data.status){
              if(result.data.data.notice.length > 0){
                notice = result.data.data.notice;
              }
              if(result.data.data.error.length > 0){
                error = result.data.data.error;
              }
              if(result.data.data.show.length > 0){
                shows = result.data.data.show;
              }
              total = result.data.data.total;
            }
            
            if(notice){
              notice.forEach(e => {
                e.status = 0;
              })
              this.notices = notice;
            }
            if(error){
              this.errors = error;
            }
            let showId = '';
            if(shows && shows.length > 0 && type!=1){
              this.$notify.closeAll();
              this.noticeIds = shows.map(e => e.id);
              for(let i=0; i<shows.length; i++){
                let notice = shows[i];
                this.$notify({
                  title: notice.title,
                  message: notice.overview,
                  duration: 120000,
                  position: 'bottom-right',
                  type:'info',
                  onClick:() => {this.onOpenNotice(i)},
                });
              }
            
            }
            this.warnTotal = total + this.errors.length;

            if(this.notices.length > 0 || this.errors.length > 0){
              _this.dialogWarnVisible = true;
            }else{
              this.notices = [];
              this.errors = [];
            }
          }else{
              this.notices = [];
              this.errors = [];
          }
        });

    },
    onOpenNotice(index){
        this.currNoticeIdx = index;
        axios.request({
            url: this.$kit.api.notice.updateStatus+"?id="+this.noticeIds[index], 
            method: 'get',
            headers: {"Content-Type":"application/json; charset=utf-8",
                "X-Requested-With":"XMLHttpRequest",
            },
            withCredentials: true,
        }).then((result) => {
            this.noticeViewVisible = true;
        });
    },
    closeWainDialog(){  //关闭查看消息弹窗
      this.dialogWarnVisible = false;
      this.notices.splice(0,this.notices.length);
      this.errors.splice(0,this.errors.length);
    },
    getTotalMessage(){ //获取总消息数
      
      if(this.$route.path.indexOf('login')>-1){
        return;
      }
      axios.request({
            url: this.$kit.api.message.totalUnread, 
            method: 'post',
            headers: {"Content-Type":"application/json; charset=utf-8",
                    "X-Requested-With":"XMLHttpRequest",
                },
            withCredentials: true,
        }).then((result) => {
          if(result.data && result.data > 0){
            this.totalMessage = result.data;
          }
        });

    },
    getWorkOrderMessage(){ //获取总消息数
      var _this = this;
      if(this.$route.path.indexOf('login')>-1){
        return;
      }
      axios.request({
        url: this.$kit.api.workordermsg.totalUnread,
        method: 'post',
        headers: {"Content-Type":"application/json; charset=utf-8",
          "X-Requested-With":"XMLHttpRequest",
        },
        withCredentials: true,
      }).then((result) => {
        if(result.data && result.data > 0){
          var message = '您有' + result.data + '条待处理的工单消息，请及时处理！'
          _this.$notify({
            title: _this.msg('工单处理提醒'),
            message: message,
            position: 'bottom-right',
            duration: 30000,
            onClick: function () {
              _this.$tabs.open("/admin/workordermy");
            }
          });
        }
      });

    },
    toCharting(){ //跳转到我的消息页面
      // this.$router.push("/admin/chart");
      this.$tabs.open("/admin/chat");
    },
    userSetting(){
      this.$tabs.open("/admin/user/setting");
    },

    openViewNotice(){
      this.$notify.closeAll();
      this.$nextTick(() => {
        this.$refs.noticeViewRef.init(this.noticeIds[this.currNoticeIdx]);
      });
    },
    //显示vip到期提醒
    showVipTip(){
      let vbd = this.user.vipBalanceDay;
      if(vbd && vbd<=3 && vbd > 0){
          let msg = this.fmt('会员还有%s天到期', [vbd+'']);
          const h = this.$createElement;
          this.$notify({
            title: this.msg('会员提醒'),
            message: h('i', { style: 'color: red'}, msg),
            duration: 30000
          });
      }
    },
    //显示钱包欠费提醒
    showWalletTip(){
      if(this.$route.path.indexOf('login')>-1){
        return;
      }
      axios.request({
            url: this.$kit.api.wallet.showTip, 
            method: 'post',
            headers: {"Content-Type":"application/json; charset=utf-8",
                    "X-Requested-With":"XMLHttpRequest",
                },
            withCredentials: true,
        }).then((result) => {
          if(result.status && result.data && result.data.data < 0){
            let balance = Math.abs((result.data.data*0.01).toFixed(2));
            let msg = this.fmt('您有(%s)元运费因账号余额不足尚未完成支付，请及时充值进行支付!【温馨提示：当欠费额度超过货运给您的信用额度时，如未及时支付运费，因系统自锁功能，货运将无法再操作您的货物！】',[balance+'']);
            const h = this.$createElement;
            this.$notify({
              title: this.msg('欠费提醒'),
              message: h('i', { style: 'color: red'}, msg),
              duration: 30000
            });
          }
        });
    },
    //跳转到vip页面
    gotoVip(){
      let _this = this;
      this.$axios.get(this.$kit.api.vip.checkBuyEnable)
          .then((result)=>{
              if(result.status){
                _this.$tabs.open("/admin/vip");
              }else{
                _this.$message({
                  message: _this.msg('请使用主账号续费！'),
                  type: 'error'
              });
              }
          });
    },
    //页面跳转
    doRedirect(){
      if(this.user== null) {
        this.$router.push("/login");
        return false;
      }
      let rdUrl = this.$kit.getQueryValue("url");
      if(rdUrl == 'shop'){
        this.$router.push("/admin/shop");
        return false;
      }

      let param = this.$kit.getQueryValue("refresh");
      if(param === "true"){
        this.$axios.get(this.$kit.api.user.reloadUser)
          .then(res => {
            if(res.status){
              //存数据，
              let user = res.data;
              let vipBalanceDay = user.vipBalanceDay;
              if(vipBalanceDay<=0){
                user.vipDayTxt = "已到期";
              }else {
                if(vipBalanceDay <= 30){
                  user.vipDayTxt = vipBalanceDay+"天";
                }else {
                  user.vipDayTxt = (vipBalanceDay/30).toFixed()+"月";
                }
              }

              localStorage.setItem("user", JSON.stringify(user));
              this.user = user;

              window.location.href = window.location.href.split("?")[0];
            }
          });
        return true;
      }
      return true;
    }

  }
}
</script>
<style scoped lang="scss">
.xcontainer{width:100%; height:100%; position:relative; }
.xheader{width:100%; display:flex; background: white;}

.xlogo{ padding-left:15px; display:flex; align-items: center; font-size:24px; line-height:100%; cursor: pointer;}
.logo-wrap{width:48px; height:48px; overflow:hidde; margin-right:10px; }
.logo-wrap img{width:48px;}

.head-bar1{width: 100%; display:flex; justify-content: space-between; 
  align-items: center; 
}

.header-title{font-size:18px; color:#5a5a5a; margin-left:15px;}

.photo-wrap{width:20px; height:20px; overflow: hidden; cursor:pointer;}
.photo-wrap img{width:20px;}

.xtool{display:flex; align-items: center;} 
.msg-badge{font-size:22px; color: #8a8a8a; padding-left:10px; cursor:pointer;}
.msg-badge .el-badge__content.is-fixed{top: 7px; right:15px;}
.switch-lang .iconfont{font-size:22px; color: #8a8a8a; cursor:pointer; padding-right:10px;}
.switchSkin .iconfont{font-size:22px; color: #8a8a8a; cursor:pointer; padding-left:10px; padding-right:10px;}
.msg-badge:hover, .switch-lang .iconfont:hover{color:#555555;}

.xcenter{display:flex; }
.xmenu{background: rgb(84, 92, 100); height: 100%;}
.xmain{width:100%; 
  overflow: hidden;
} 

.company-aside{flex: 0 0 201px;  background: rgb(84, 92, 100);}
.xcenter-aside{max-height: calc(100% - 48px);}
.xcenter-aside .xmain{flex: 0 0 calc(100% - 201px);}

.head-bar1-aside{box-shadow: 0px 5px 5px rgba(84, 92, 100, 0.2);}

.el-dropdown-menu__item >>> i{font-style: normal;}

.company-top{width:250px;}
.company-top .xlogo{color:#8a8a8a; } 

.header-bar-top .header-title{margin-left:0px;}

.xcenter-top{flex-direction: column; } 
.xcenter-top .xmain{flex: 0 0 100%;}
.xcenter-top .xmenu{height: 60px;}
.xcenter-top .xmenu .el-menu--horizontal{display:flex;  }

.xcenter-aside .xmenu{flex: 0 0 201px; }
.xcenter-aside >>> .ctrl-left, .xcenter-top.xcenter-min >>> .ctrl-left{padding-left:5px; }
.xcenter-aside .xmenu >>> .iconfont{font-size:20px;}

.xcenter-aside.xcenter-min >>> .xmenu{flex: 0 0 64px;} 
.xcenter-aside.xcenter-min .xmenu{flex: 0 0 64px;} 

.is-active .el-submenu__title i{
  color:inherit;
}


/*
.xmenu-top .el-menu-item{height:40px; line-height:40px;}
.xmenu-top .el-submenu .el-submenu__title{height:40px; line-height:40px;}
*/

.xtool >>> .el-dropdown-menu__item{font-size:15px;}

.logo-min-wrap{width:32px; height:32px; overflow:hidde; margin-right:10px; }
.logo-min-wrap img{width:32px;} 

.company-min{padding: 10px 0px; flex: 0 0 64px;} 
.company-top.company-min{padding: 10px 0px; flex: 0 0 50px;} 
.company-top.company-min .xlogo{padding-left:10px;}


.xcenter-min .xmain{flex: 0 0 calc(100% - 65px);}
.xcenter-full .xmain{flex: 0 0 100%;}

.top-bg{
  background: url('~@/assets/img/top.jpg'); 
  background-repeat: no-repeat;
   background-size: cover;
}

.user-card{
   width:180px;
   position:relative;
   overflow:hidden;
}

.user-photo-wrap{
   width: 50px; height:50px; overflow:hidden; position:relative;
   background: #9BA1DD; border-radius: 50%; border: 2px solid rgba(255,255,255, 0.8); 
   box-shadow: 0 0 10px rgba(0,0,0,0.3);
   left: 62px; top:20px;
}


.user-photo-wrap > img{width: 50px; height:50px; }
.user-photo-wrap > div{display:inline-block; color: white; font-size:34px; font-weight:bold; padding-left:8px;}

.card-fullname{text-align:center; margin-top:16px; color: #666; font-size:18px;}

.odd{margin-left:15px;}
.user-tool{margin:5px 5px 15px 5px;} 
.user-tool > *{
  margin-top: 5px; margin-bottom:5px;
}
.head-bar1-min{display:block;}
.head-bar1-min .xtool{display:block; text-align:right;}
</style>
 

var dict = {
    data(){
      return{
        dicts: {
            views: {
                userView : 0,
                stockView : 1,
            },
            cssTypes: [
                'warning',
                'success',
                'primary',
                'info',
                'danger',
                'success',
                'success',
                'success',
                'success',
                'success',
                'success',
                'success',
            ],
            
            //查询条件类型
            //0-文本，1-数字，2-下拉，3-日期范围
            queryTypes: [
                {
                    label: this.msg("文本输入"),
                    key: 0,
                    value: [ //文本 操作符号
                        {label: this.msg("等于"), value: 0},
                        {label: this.msg("关键字包含"), value: 5},
                        {label: this.msg("关键字左包含"), value: 9},
                        {label: this.msg("多个文本"), value: 6},
                    ],
                },
                {
                    label: this.msg("数字输入"),
                    key:1,
                    value: [ //数字 操作符号
                        {label: this.msg("等于"), value: 0},
                        {label: this.msg("大于"), value: 1},
                        {label: this.msg("大于等于"), value: 3},
                        {label: this.msg("小于"), value: 2},
                        {label: this.msg("小于等于"), value: 4},
                    ],
                },
                {
                    label: this.msg("下拉框"),
                    key:2,
                    value: [ //数字 操作符号
                        {label: this.msg("单选"), value: 0},
                        {label: this.msg("多选"), value: 6},
                        {label: this.msg("多选（不包含）"), value: 8},
                    ],
                },
                {
                    label: this.msg("日期选择"),
                    key:3
                },
                {
                    label: this.msg("日期时间选择"),
                    key:4
                },
            ],
            
            dateRanges: [
                {label: this.msg("近1天"), value: "1"},
                {label: this.msg("近3天"), value: "3"},
                {label: this.msg("近一周"), value: "7"},
                {label: this.msg("近两周"), value: "14"},
                {label: this.msg("近10天"), value: "10"},
                {label: this.msg("近半月"), value: "15"},
                {label: this.msg("近一月"), value: "30"},
            ],

            filterTypes: [
                {label: this.msg('搜索筛选器'), value: "search"}, //FilterInput
                {label: this.msg('内容分组筛选器'), value: "content"},   //FilterContent
                {label: this.msg('类型筛选器'), value: "type"}, 
                {label: this.msg('复合筛选器'), value: "complex"},   //FilterComplex
                {label: this.msg('日期筛选器'), value: "date"},   //FilterComplex
            ],

            adminTypes:[
                {label: this.msg("普通用户"), value: 0, type:"success"},
                {label: this.msg("管理员"), value: 1, type: "danger"},
            ],
            declareStatuses:[
                {label: this.msg("待调用"), value: 0, type:"primary"},
                {label: this.msg("调用中"), value: 1, type: "success"},
                {label: this.msg("已使用"), value: 2, type: "danger"},
            ],
            declareTypes:[
                {label: this.msg("貨到付款"), value: 1, type:"warning"},
                {label: this.msg("純配送"), value: 3, type: "success"},
            ],
            genders:[
                {label: this.msg("男"), value: 0, type:"primary"},
                {label: this.msg("女"), value: 1, type:"warning"},
                {label: this.msg("保密"), value: 2, type:"success"},
            ],
            walletStatus:[
                {label: this.msg("未审核"), value: 0, type:"primary"},
                {label: this.msg("已审核"), value: 1, type:"success"},
                {label: this.msg("已取消"), value: 2, type:"warning"},
            ],
            walletPayStatus:[
                {label: this.msg("待付"), value: 0, type:"warning"},
                {label: this.msg("未结清"), value: 1, type:"warning"},
                {label: this.msg("已付"), value: 2, type:"success"},
                {label: this.msg("已结算"), value: 3, type:"success"},
                {label: this.msg("已取消"), value: 4, type:"success"},
            ],
            walletTypes: [
                // 0-充值,1-会员续费、2-提现,  3-运费结算、4-回款结算、5-其它支出
                {label: this.msg("充值"), value: 0, type:"primary"},
                // {label: this.msg("会员续费"), value: 1, type:"primary"},
                {label: this.msg("提现"), value: 2, type:"primary"},
                {label: this.msg("运费结算"), value: 3, type:"primary"},
                {label: this.msg("回款结算"), value: 4, type:"primary"},
                {label: this.msg("理赔"), value: 5, type:"primary"},
                {label: this.msg("其它支出"), value: 6, type:"primary"},
                {label: this.msg("客诉件结算"), value: 7, type:"primary"},
            ],
            walletTypes1: [
                // 0-充值,1-会员续费、2-提现,  3-运费结算、4-回款结算、5-其它支出
                {label: this.msg("充值"), value: 0, type:"primary"},
                {label: this.msg("会员续费"), value: 1, type:"primary"},
                {label: this.msg("提现"), value: 2, type:"primary"},
                {label: this.msg("运费结算"), value: 3, type:"primary"},
                {label: this.msg("回款结算"), value: 4, type:"primary"},
                {label: this.msg("理赔"), value: 5, type:"primary"},
                {label: this.msg("其它支出"), value: 6, type:"primary"},
                {label: this.msg("客诉件结算"), value: 7, type:"primary"},
            ],
            vipTypes:[
                {label: this.msg("普通用户"), value: 0, txt: "vip0", type:"primary"},
                {label: this.msg("普通会员"), value: 1,txt: "vip1",  type:"primary"},
                {label: this.msg("高级会员"), value: 2,txt: "vip2",  type:"success"},
                {label: this.msg("超级会员"), value: 3,txt: "vip3",  type:"danger"},
                {label: this.msg("试用会员"), value: 4,txt: "vip4",  type:"danger"},
                {label: this.msg("电商会员"), value: 5,txt: "vip5",  type:"danger"},
                {label: this.msg("物流会员"), value: 6,txt: "vip6",  type:"danger"},
                {label: this.msg("物流会员【标准版】"), value: 7,txt: "vip7",  type:"danger"},
                {label: this.msg("电商会员【标准版】"), value: 8,txt: "vip8",  type:"danger"},
                {label: this.msg("物流公司"), value: 9,txt: "vip9",  type:"danger"},
            ],
            vipOrderStatus:[
                {label: this.msg("待支付"), value: 0, type:"primary"},
                {label: this.msg("已支付"), value: 1, type:"success"},
                {label: this.msg("支付成功"), value: 2, type:"success"},
                {label: this.msg("未付款交易超时关闭"), value: 3, type:"info"},
                {label: this.msg("交易结束，不可退款"), value: 4, type:"danger"},
                {label: this.msg("取消支付"), value: 5, type:"danger"},
                {label: this.msg("已付款"), value: 6, type:"success"},
            ],
            userEditStatuses:[
                {label: this.msg("待审核"), value: 0},
                {label: this.msg("预付费"), value: 1},
                {label: this.msg("正常"), value: 5},
                {label: this.msg("审核不通过"), value: 10},
                {label: this.msg("禁用"), value: 15},
                {label:this.msg("已删除"),value:20},
            ],
            userStatuses:[
                {label:this.msg("待审核"),value:0, type:""},
                {label: this.msg("预付费"), value: 1},
                {label:this.msg("正常"),value:5, type:"success"},
                {label:this.msg("审核不通过"),value:10, type:"warning"},
                {label:this.msg("禁用"),value:15, type:"danger"},
                {label:this.msg("已删除"),value:20, type:"danger"},
            ],
            defaulteds:[ //是否默认
                {label:this.msg("否"),value:0, type:"warning"},
                {label:this.msg("是"),value:1, type:"success"},
            ],
            viewOnwerTypes: [
                {label: this.msg("所有视图"), value: 0},
                {label: this.msg("自定义视图"), value: 1},
                {label: this.msg("系统视图"), value: 2},
            ],
            viewCategories: [
                {label: this.msg("账户字段"), value: 0},
                {label: this.msg("仓库字段"), value: 1},
            ],
            readStatuses: [
                {label: this.msg("未读"), value: 0, type:"primary"},
                {label: this.msg("已读"), value: 1, type: "success"},
            ],
            messageTypes: [
                {label: this.msg("文本"), value: 0, type:"primary"},
                {label: this.msg("图片"), value: 1, type: "success"},
                {label: this.msg("文件"), value: 2, type: "danger"},
            ],
            noticeStatus:[
                {label: this.msg("未读"), value: 0, type:"warning"},
                {label: this.msg("已读"), value: 1, type:"success"},
            ],
            authType:[
                {label: this.msg("私有"), value: 0, type:"danger"},
                {label: this.msg("半公开"), value: 1, type:"warning"},
                {label: this.msg("公开"), value: 2, type:"success"},
            ],
            //计费方式
            feeTypes:[
                {label: this.msg("空运"), value: 0, type:"primary"},
                {label: this.msg("海运"), value: 1, type:"warning"},
                {label: this.msg("特殊1"), value: 3, type:"warning"},
                {label: this.msg("特殊2"), value: 4, type:"warning"},
                {label: this.msg("特殊3"), value: 5, type:"warning"},
                {label: this.msg("按票"), value: 2, type:"success"},
            ],
            //计重策略
            weightStraties:[
                {label: this.msg("实际重量"), value: 0, type:"primary"},
                {label: this.msg("向上取整"), value: 1, type:"warning"},
                {label: this.msg("全包"), value: 2, type:"success"},
            ],
            //单位类型
            units:[
                {label: this.msg("元/公斤"), value: 0, type:"primary"},
                {label: this.msg("元/票"), value: 1, type:"warning"},
            ],
            //货币币种
            currencies:[
                {label: this.msg("人民币"), value: 0, type:"primary"},
                {label: this.msg("台币"), value: 1, type:"warning"},
            ],
            userPkgTypes:[
                {label: this.msg("普通"), value: 0, type:"primary"},
                {label: this.msg("精细"), value: 1, type:"success"},
            ],
            stockExpPageStatus:[//台湾物流面单状态
                {label: this.msg("待通知"), value: 0},
                {label: this.msg("待上传"), value: 5},
                {label: this.msg("待处理"), value: 6},
                {label: this.msg("待同步"), value: 7},
                {label: this.msg("待打印"), value: 10},
                {label: this.msg("已打印"), value: 15},
                {label: this.msg("已过期"), value: 20},
            ],
            stockExpType:[//配送方式
                {label: this.msg("純配送"), value: 3},
                {label: this.msg("貨到付款"), value: 1},
            ],
            stockExpGoodType:[//货物类型
                {label: this.msg("普货"), value: 0},
                {label: this.msg("特货"), value: 1},
            ],
            stockExpPkgType:[//打包类型
                {label: this.msg("仓储包"), value: 0},
                {label: this.msg("发货打包"), value: 1},
                {label: this.msg("货运打包"), value: 5},
                {label: this.msg("转运打包"), value: 10},
                {label: this.msg("仓储现货包"), value: 15},
                {label: this.msg("拆合转寄包"), value: 20},
                {label: this.msg("单票转寄包"), value: 25},
                {label: this.msg("过期转寄包"), value: 30},
                {label: this.msg("弃件打包"), value: 35},
                {label: this.msg("寄回打包"), value: 40},
            ],
            stockStoreType:[//仓储类型
                {label: this.msg("现货仓储"), value: 5},
                {label: this.msg("退件仓储"), value: 10},
                {label: this.msg("备货仓储"), value: 20},
                {label: this.msg("现收现发"), value: 30},
                {label: this.msg("过期仓储"), value: 35},
                {label: this.msg("集运仓储"), value: 40},
            ],
            stockStoreType1:[//仓储类型1
                {label: this.msg("现货仓储"), value: 5},
                {label: this.msg("退件仓储"), value: 10},
                {label: this.msg("备货仓储"), value: 20},
                {label: this.msg("过期仓储"), value: 35},
            ],
            stockStoreType2:[//仓储类型2
                {label: this.msg("现货仓储"), value: 5},
                {label: this.msg("备货仓储"), value: 20},
            ],
            stockInStoreStatus:[//入仓标签
                {label: this.msg("未打印"), value: 0},
                {label: this.msg("已打印"), value: 1},
            ],
            stockExpAllowPrint:[//能否列印面单
                {label: this.msg("不可以"), value: 0},
                {label: this.msg("可以"), value: 1},
                {label: this.msg("面单出货"), value: 2},
                {label: this.msg("自动获取"), value: 3},
            ],
            stockErrStatus:[//异常状态
                {label: this.msg("正常"), value: 0, type:"success"},
                {label: this.msg("异常"), value: 1, type:"danger"},
                {label: this.msg("已处理异常"), value: 2, type:"warning"},
                {label: this.msg("建单异常"), value: 3, type:"info"},
                {label: this.msg("萊爾富新面单"), value: 4, type:"primary"},
                {label: this.msg("待处理异常"), value: 5, type:"primary"},
            ],
            stockHasTax:[//是否含税
                {label: this.msg("不含税"), value: 0},
                {label: this.msg("含税"), value: 1},
            ],
            isPublics :[//是否含税
                {label: this.msg("公共"), value: 0},
                {label: this.msg("私有"), value: 1},
            ],
            shopTypes:[
                {label: this.msg("虾皮"), value: 0, type:"success"},
                {label: this.msg("露天"), value: 1, type:"success"},
                {label: this.msg("奇摩"), value: 2, type:"success"},
                {label: this.msg("淘宝台湾"), value: 3, type:"success"},
                {label: this.msg("PChome"), value: 4, type:"success"},
                {label: this.msg("绿界"), value: 5, type:"success"},
                {label: this.msg("超商"), value: 6, type:"success"},
                {label: this.msg("虾皮(离线)"), value: 7, type:"success"},
                {label: this.msg("樂天市場"), value: 8, type:"success"},
                {label: this.msg("Shopline"), value: 9, type:"success"},
            ],
            orderOrigin:[
                {label: this.msg("店配"), value: 0, type:"success"},
                {label: this.msg("线下"), value: 1, type:"success"},
                {label: this.msg("转运"), value: 2, type:"success"},
                {label: this.msg("PDF"), value: 3, type:"success"},
                {label: this.msg("仓储"), value: 4, type:"success"},
                {label: this.msg("弃件"), value: 6, type:"danger"},
                {label: this.msg("寄回"), value: 7, type:"warning"},
                {label: this.msg("集运"), value: 8, type:"warning"},
                {label: this.msg("海快"), value: 9, type:"warning"},
            ],
            orderPlatforms:[
                {label: this.msg("虾皮"), value: 0, type:"success"},
                {label: this.msg("露天"), value: 1, type:"success"},
                {label: this.msg("奇摩"), value: 2, type:"success"},
                {label: this.msg("淘宝台湾"), value: 3, type:"success"},
                {label: this.msg("PC home"), value: 4, type:"success"},
                {label: this.msg("绿界"), value: 5, type:"success"},
                {label: this.msg("超商"), value: 6, type:"success"},
                {label: this.msg("虾皮(离线)"), value: 7, type:"success"},
                {label: this.msg("樂天市場"), value: 8, type:"success"},
                {label: this.msg("Shopline"), value: 9, type:"success"},
            ],
            expoutType:[//出货方式
                //0-仓储贴面单，1-贴面单出货，2-线下已建单，3-线下未建单，4-弃件下架，5-退件寄回，6-打包验货',
                {label: this.msg("仓储贴面单"), value: 0, type:"success"},
                {label: this.msg("贴面单出货"), value: 1, type:"success"},
                {label: this.msg("线下已建单"), value: 2, type:"success"},
                {label: this.msg("线下未建单"), value: 3, type:"success"},
                {label: this.msg("弃件下架"), value: 4, type:"danger"},
                {label: this.msg("退件寄回"), value: 5, type:"warning"},
                {label: this.msg("打包验货"), value: 6, type:"success"},
            ],
            transportType:[//运输方式
                //0-海快，1-空运，2-新航线
                {label: this.msg("无"), value: "无", type:"success"},
                {label: this.msg("海快"), value: "海快", type:"success"},
                {label: this.msg("空运"), value: "空运", type:"success"},
                {label: this.msg("新航线"), value: "新航线", type:"success"},
            ],
            transportTypeInout:[//运输方式
                //0-海快，1-空运，2-新航线
                {label: this.msg("海快"), value: "海快", type:"success"},
                {label: this.msg("空运"), value: "空运", type:"success"},
                {label: this.msg("新航线"), value: "新航线", type:"success"},
            ],
            batchStatus:[
                {value: 75, label: "转配送", type: "primary"},
            ],
            expCompany: [
                {label: this.msg("7-11"), value: '7-11', type:"info"},
                {label: this.msg("全家"), value: '全家', type:"danger"},
                {label: this.msg("萊爾富"), value: '萊爾富', type:"primary"},
                {label: this.msg("OK"), value: 'OK', type:"warning"},
                {label: this.msg("黑貓"), value: '黑貓', type:"success"},
                {label: this.msg("賣家宅配"), value: '賣家宅配', type:"success"},
                {label: this.msg("黑貓宅急便"), value: '黑貓宅急便', type:"success"},
                {label: this.msg("蝦皮宅配"), value: '蝦皮宅配', type:"success"},
                {label: this.msg("宅配通"), value: '宅配通', type:"success"},
                {label: this.msg("蝦皮店到店"), value: '蝦皮店到店', type:"success"},
                {label: this.msg("店到家宅配"), value: '店到家宅配', type:"success"},
                {label: this.msg("香港件"), value: '香港件', type:"success"},
                {label: this.msg("新竹"), value: '新竹', type:"info"},
                {label: this.msg("大荣"), value: '大荣', type:"primary"},
                {label: this.msg("东风"), value: '东风', type:"primary"},
                {label: this.msg("海快"), value: '海快', type:"primary"},
                {label: this.msg("海快蝦宅"), value: '海快蝦宅', type:"primary"},
                {label: this.msg("聚鼎"), value: '聚鼎', type:"primary"},
                {label: this.msg("露天拍卖"), value: '露天拍卖', type:"success"},
                {label: this.msg("无配送"), value: '无配送', type:"success"},
                {label: this.msg("菲律宾"), value: 'J&T Expres', type:"success"},
                {label: this.msg("自提"), value: '自提', type:"success"},
                {label: this.msg("本地仓"), value: '本地仓', type:"success"},
                {label: this.msg("嘉里快遞"), value: '嘉里快遞', type:"success"},
            ],
            expCompanyCode:[
                {label: this.msg("7-11"), value: 'YY'},
                {label: this.msg("全家"), value: 'JJ'},
                {label: this.msg("萊爾富"), value: 'EE'},
                {label: this.msg("OK"), value: 'KK'},
                {label: this.msg("黑貓"), value: 'MM'},
                {label: this.msg("賣家宅配"), value: 'MZ'},
                {label: this.msg("黑貓宅急便"), value: 'BB'},
                {label: this.msg("蝦皮宅配"), value: 'PP'},
                {label: this.msg("宅配通"), value: 'PP'},
                {label: this.msg("蝦皮店到店"), value: 'DD'},
                {label: this.msg("店到家宅配"), value: 'DJ'},
                {label: this.msg("新竹"), value: 'XX'},
                {label: this.msg("大荣"), value: 'RR'},
                {label: this.msg("东风"), value: 'FF'},
                {label: this.msg("海快"), value: 'HH'},
                {label: this.msg("海快蝦宅"), value: 'HP'},
                {label: this.msg("香港件"), value: 'HK'},
                // {label: this.msg("海运"), value: '海运'},
                // {label: this.msg("空运"), value: '空运'},
                {label: this.msg("聚鼎"), value: 'UU'},
                {label: this.msg("露天拍卖"), value: 'TT'},
                {label: this.msg("无配送"), value: 'WW'},
                {label: this.msg("菲律宾"), value: 'LL'},
                {label: this.msg("自提"), value: 'ZT'},
                {label: this.msg("本地仓"), value: 'XH'},
                {label: this.msg("嘉里快遞"), value: 'JL'},
            ],
            expCompanyInout: [
                {label: this.msg("7-11"), value: '7-11', type:"info"},
                {label: this.msg("全家"), value: '全家', type:"danger"},
                {label: this.msg("萊爾富"), value: '萊爾富', type:"primary"},
                {label: this.msg("OK"), value: 'OK', type:"warning"},
                {label: this.msg("黑貓"), value: '黑貓', type:"success"},
                {label: this.msg("賣家宅配"), value: '賣家宅配', type:"success"},
                {label: this.msg("黑貓宅急便"), value: '黑貓宅急便', type:"success"},
                {label: this.msg("蝦皮宅配"), value: '蝦皮宅配', type:"success"},
                {label: this.msg("宅配通"), value: '宅配通', type:"success"},
                {label: this.msg("蝦皮店到店"), value: '蝦皮店到店', type:"success"},
                {label: this.msg("店到家宅配"), value: '店到家宅配', type:"success"},
                {label: this.msg("香港件"), value: '香港件', type:"success"},
                {label: this.msg("新竹"), value: '新竹', type:"info"},
                {label: this.msg("大荣"), value: '大荣', type:"primary"},
                {label: this.msg("东风"), value: '东风', type:"primary"},
                {label: this.msg("海快"), value: '海快', type:"primary"},
                {label: this.msg("海快蝦宅"), value: '海快蝦宅', type:"primary"},
                // {label: this.msg("海运"), value: '海运', type:"primary"},
                // {label: this.msg("空运"), value: '空运', type:"primary"},
                {label: this.msg("聚鼎"), value: '聚鼎', type:"primary"},
                {label: this.msg("露天拍卖"), value: '露天拍卖', type:"success"},
                {label: this.msg("无配送"), value: '无配送', type:"success"},
                {label: this.msg("菲律宾"), value: 'J&T Expres', type:"success"},
                {label: this.msg("嘉里快遞"), value: '嘉里快遞', type:"success"},
            ],
            transformType:[
                // {label: this.msg("空运"), value: '空运', type:"primary"},
                // {label: this.msg("海运"), value: '海运', type:"success"},
                {label: this.msg("无配送"), value: '无配送', type:"success"},
                {label: this.msg("自退"), value: '自退', type:"danger"},
                {label: this.msg("本地仓"), value: '本地仓', type:"danger"},
            ],
            stockStatus: [
                {value: 0, label: "待入仓", type: "primary"},
                {value: 5, label: "待认领", type: "primary"},
                {value: 10, label: "已入仓", type: "success"},
                {value: 13, label: "待备货", type: "warning"},
                {value: 15, label: "备货中", type: "primary"},
                {value: 20, label: "待打包", type: "warning"},
                {value: 23, label: "已打单", type: "success"},
                {value: 25, label: "打包中", type: "warning"},
                {value: 30, label: "待出仓", type: "primary"},
                {value: 35, label: "已打包", type: "success"},
                {value: 40, label: "待揽收", type: "primary"},
                {value: 45, label: "已揽收", type: "primary"},
                {value: 50, label: "已发货", type: "success"},
                {value: 55, label: "转运中", type: "primary"},
                {value: 60, label: "待验中", type: "primary"},
                {value: 65, label: "已清关", type: "primary"},
                {value: 70, label: "已提货", type: "success"},
                {value: 75, label: "转配送", type: "primary"},
                {value: 80, label: "截货中", type: "danger"},
                {value: 85, label: "已拆包", type: "primary"},
                {value: 90, label: "待转寄", type: "primary"},
                {value: 95, label: "转寄中", type: "primary"},
                {value: 100, label: "已寄出", type: "primary"},
                {value: 105, label: "待寄回", type: "primary"},
                {value: 110, label: "下架中", type: "primary"},
                {value: 115, label: "寄回中", type: "primary"},
                {value: 120, label: "已寄回", type: "primary"},
                {value: 125, label: "待弃件", type: "primary"},
                {value: 130, label: "弃件中", type: "primary"},
                {value: 135, label: "已弃件", type: "primary"},
            ],
            //在order中查询所需要的状态值
            stockStatusInOrder: [
                {value: 13, label: "待备货", type: "warning"},
                {value: 15, label: "备货中", type: "primary"},
                {value: 20, label: "待打包", type: "warning"},
                {value: 23, label: "已打单", type: "success"},
                {value: 25, label: "打包中", type: "warning"},
                {value: 80, label: "截货中", type: "danger"},
            ],
            stockStatus1: [
                {value: 10, label: "已入仓", type: "success"},
                {value: 85, label: "已拆包", type: "primary"},
                {value: 100, label: "已寄出", type: "primary"},
            ],
            filterUserOptionTypes: [
                {label: this.msg("用户身份标识"), key: "adminTypes"},
                {label: this.msg("性别"), key: "genders"},
                {label: this.msg("用户会员身份"), key: "vipTypes"},
                {label: this.msg("用户状态"), key: "userEditStatuses"},
                {label: this.msg("是否默认"), key: "defaulteds"},
            ],
            filterStoreOptionTypes: [
                {label: this.msg("面单状态"), key: "stockExpPageStatus"},
                {label: this.msg("配送类别"), key: "stockExpType"},
                {label: this.msg("打包类型"), key: "stockExpPkgType"},
                {label: this.msg("仓储类型"), key: "stockStoreType"},
                {label: this.msg("入仓标签"), key: "stockInStoreStatus"},
                {label: this.msg("异常状态"), key: "stockErrStatus"},
                {label: this.msg("是否含税"), key: "stockHasTax"},
                {label: this.msg("店铺类型"), key: "shopTypes"},
                {label: this.msg("台湾物流公司"), key: "expCompany"},
                {label: this.msg("货物状态"), key: "stockStatus"},
                {label: this.msg("能否列印面单"), key: "stockExpAllowPrint"},
                {label: this.msg("货物类型"), key: "stockExpGoodType"},
                {label: this.msg("出货方式"), key: "expoutType"},
                {label: this.msg("订单来源"), key: "orderOrigin"},
                {label: this.msg("订单卖场"), key: "orderPlatforms"},
                {label: this.msg("是否取消"), key: "cances"},
                {label: this.msg("异常类型"), key: "errorTypes"},
                {label: this.msg("理赔状态"), key: "payStatus"},
                {label: this.msg("计费方式"), key: "feeTypes"},
                {label: this.msg("运费结算状态"), key: "transitStatuses"},
                {label: this.msg("回款结算状态"), key: "payInStatuses"},
                {label: this.msg("采购状态"), key: "purchaseStatuses"},
                {label: this.msg("等级"), key: "levelStatuses"},
                {label: this.msg("物流状态"), key: "trackStatus"},
            ],
            cances:[
                {value: 0, label: "正常", type: "success"},
                {value: 1, label: "取消", type: "danger"},
            ],
            transitTypes:[
                {value: 0, label: this.msg("空运"), type: "success"},
                {value: 1, label: this.msg("海运"), type: "primary"},
                {value: 2, label: this.msg("按票"), type: "danger"},
            ],
            //运费结算状态
            transitStatuses:[
                {value: 0, label: this.msg("未计算"), type: "success"},
                {value: 1, label: this.msg("待付"), type: "primary"},
                {value: 2, label: this.msg("已付"), type: "danger"},
                {value: 3, label: this.msg("已出账单"), type: "danger"},
            ], 
            //回款结算状态
            payInStatuses:[
                {value: 0, label: this.msg("未计算"), type: "success"},
                {value: 1, label: this.msg("待付"), type: "primary"},
                {value: 2, label: this.msg("已付"), type: "danger"},
            ], 
            purchaseStatuses:[
                {value: 0, label: this.msg("待采购"), type: "danger"},
                {value: 1, label: this.msg("已采购"), type: "success"},
            ], 
            levelStatuses:[
                {value: 0, label: this.msg("无"), type: "success"},
                {value: 1, label: this.msg("一级"), type: "primary"},
                {value: 2, label: this.msg("二级"), type: "warning"},
                {value: 3, label: this.msg("三级"), type: "danger"},
            ],
            errorTypes:[
                {label: this.msg("MD面单异常"), value: this.msg("MD面单异常")},
                {label: this.msg("WD没有面单"), value: this.msg("WD没有面单")},
                {label: this.msg("GQ面单过期"), value: this.msg("GQ面单过期")},
                {label: this.msg("BG包裹破损"), value: this.msg("BG包裹破损")},
                {label: this.msg("CC包裹超材"), value: this.msg("CC包裹超材")},
                {label: this.msg("WL物流异常"), value: this.msg("WL物流异常")},
                {label: this.msg("DZ地址异常"), value: this.msg("DZ地址异常")},
                {label: this.msg("LP理赔通报"), value: this.msg("LP理赔通报")},
                {label: this.msg("DS包裹丢失"), value: this.msg("DS包裹丢失")},
                {label: this.msg("WJ暂未见货"), value: this.msg("WJ暂未见货")},
            ],
            trackStatus:[//物流状态
                {label: this.msg("无"), value: ""},
                {label: this.msg("配送中"), value: this.msg("配送中")},
                {label: this.msg("已签收"), value: this.msg("已签收")},
                {label: this.msg("已退回"), value: this.msg("已退回")},
            ],
            payStatus:[//理赔状态
                {label: this.msg("无"), value: 0},
                {label: this.msg("未理赔"), value: 1},
                {label: this.msg("已理赔"), value: 2},
            ],
            detailReport:[//报单状态
                {label: this.msg("未报单"), value: 0,type: "danger"},
                {label: this.msg("已报单"), value: 1,type: "warning"},
                {label: this.msg("已受理"), value: 2,type: "success"},
            ],
            workOrderType: [
                {label: this.msg("查件催件"), value: 1},
                {label: this.msg("资料面单"), value: 2},
                {label: this.msg("快递包裹"), value: 3},
                {label: this.msg("财务账单"), value: 4},
                {label: this.msg("客服咨询"), value: 5},
                {label: this.msg("系统技术"), value: 6},
            ],
            workOrderLevel: [
                {label: this.msg("正常"), value: 0},
                {label: this.msg("较急"), value: 1},
                {label: this.msg("超急"), value: 2},
            ],
            workOrderStatus: [
                {label: this.msg("待受理"), value: 0},
                {label: this.msg("处理中"), value: 1},
                {label: this.msg("已完结"), value: 2},
                {label: this.msg("已评价"), value: 3},
            ],
            workOrderComment: [
                {label: this.msg("非常满意"), value: 4},
                {label: this.msg("满意"), value: 3},
                {label: this.msg("一般"), value: 2},
                {label: this.msg("差评"), value: 1},
            ],
            workOrderCommentResult: [
                {label: this.msg("未解决"), value: 0},
                {label: this.msg("部分解决"), value: 1},
                {label: this.msg("已解决"), value: 2},
            ],
            stockBtns:[
                {name: '刷新', icon: 'icon-shuaxin1', 
                    permission: null, action: 'list', para: true,
                    children: null,
                },
                {name: '高级搜索', icon: 'icon-gaojisousuo', 
                    permission: null, action: 'showAdvSearch', para: null,
                    children: null,
                },
                {name: '提交工单', icon: 'icon-xiaoxiguanli',
                    permission: null, action: 'workOrderAdd', para: null,
                    children: null,
                },
                {name: '总数', icon: 'icon-zongshu', 
                    permission: null, action: 'showSelectTotal', para: null,
                    children: null,
                },
                {name: '同步店铺订单', icon: 'icon-tongbudingdan', 
                    permission: ['stock:syncOrder'], action: 'doSyncOrder', para: null,
                    children: null,
                },
                {name: '一键发货', icon: 'icon-yijianfahuo', 
                    permission: ['stock:batchSendOrder'], action: 'doBatchSendOrderNew', para: null,
                    children: null,
                },
                {name: '同步面单', icon: 'icon-xitongshezhi_tuxiang-copy', 
                    permission: ['stock:shipOrder'], action: 'doBatchSendOrder', para: null,
                    children: null,
                },
                {name: '预览详情', icon: 'icon-chakan', 
                    permission: ['stock:detail0'], action: 'stockDetail', para: null, 
                    // iconStyle: {display: "inline-block;", marginTop: "3px"},
                    // labelStyle: {display: "inline-block;",marginTop: "4px"},
                    children: null,
                },
                {name: '货物采购', icon: 'icon-huowucaigou', 
                    permission: ['stock:buy'], action: 'stockDetailBuy', para: null,
                    children: null,
                },
                {name: '快递同步', icon: 'icon-kuaiditongbu', 
                    permission: null, action: 'doTrackExpress', para: null,
                    children: null,
                },
                {name: '批量更新', icon: 'icon-pilianggengxin1', 
                    permission: ['stock:batchFieldAll'], action: 'doBatchUpdate', para: null,
                    children: null,
                },
                {name: '入仓出仓', icon: 'icon-rucangchucang', 
                    permission: ['stock:inout'], action: 'doInOut', para: null,
                    children: null,
                },
                // {name: '待备货', icon: 'icon-daibeihuo', 
                //     permission: null, action: 'stockEditStatus', para: null,
                //     children: null,
                // },
                {name: '新增', icon: 'icon-xinzengdingdan', 
                    permission: ['stock:create0',
                                'stock:create1',
                                'stock:create2',
                                'stock:create3',
                                'stock:create4'
                                ], 
                    action: 'handleAdd', para: null,
                    children: [
                        {name: '新增库存', command:'a', permission: ['stock:create1']},
                        {name: '新增线下订单', command:'b', permission: ['stock:create2']},
                        {name: '新增店配订单', command:'c', permission: ['stock:create3']},
                        {name: '新增现货仓储', command:'d', permission: ['stock:create4']},
                    ],
                },
                {name: '导入', icon: 'icon-daoru2', 
                    permission: ['stock:import0','stock:onlyPageImport'], action: 'handleImport', para: null,
                    children: [
                        {name: '导入EXCEL资料', command:'a', permission: ['stock:import0']},
                        {name: '导入PDF资料', command:'b', permission: ['stock:onlyPageImport']}
                    ],
                },
                {name: '导出', icon: 'icon-daochu1', 
                    permission: ['stock:export',
                                'stock:downloadQingguan',
                                'stock:downloadHangkong',
                                'stock:downloadHeimao',
                                'stock:downloadTihuo',
                                'stock:downloadTongji',
                                'stock:downloadStatus'], 
                    action: 'handleExport', para: null,
                    children: [
                        {name: '导出列表', command:'a', permission: ['stock:export']},
                        {name: '导出航空资料', command:'c', permission: ['stock:downloadHangkong']},
                        {name: '导出清关资料', command:'b', permission: ['stock:downloadQingguan']},
                        {name: '导出提货表', command:'e', permission: ['stock:downloadTihuo']},
                        {name: '导出派件资料', command:'d', permission: ['stock:downloadHeimao']},
                        {name: '导出建单资料', command:'f', permission: null},
                        {name: '出货统计', command:'g', permission: ['stock:downloadTongji']},
                        {name: '导出详情', command:'h', permission: null},
                        {name: '导出物流记录', command:'j', permission: ['stock:downloadStatus']},
                        {name: '导出采购详情', command:'k', permission: null},
                        {name: '导出转寄成本', command:'m', permission: null},
                        {name: '导出面单图片', command:'n', permission: null},
                    ],
                },
                // {name: '导出面单图', icon: 'icon-daochu1',
                //     permission: null, action: 'doPagePicExport', para: null,
                //     children: null,
                // },
                {name: '打印', icon: 'icon-dayin2', 
                    permission: ['stock:print','stock:printWg','stock:printTag','declare:print','stock:printFapiao'], action: 'handlePrint', para: null,
                    children: [
                        // {name: '打印临时出货码', command:'r', permission: null},
                        // {name: '打印二维码货单', command:'w', permission: null},
                        {name: '打印拣货单', command:'a', permission: null},
                        {name: '打印出货码', command:'y', permission: null},
                        {name: '打印查询码', command:'t', permission: null},
                        {name: '打印拣货单&出货码', command:'z', permission: null},
                        {name: '打印拣货单&查询码', command:'s', permission: null},
                        {name: '打印自定义快递单', command:'l', permission: null},
                        {name: '打印拣货单&自定义快递单', command:'r', permission: null},
                        {name: '打印中通快递单', command:'v', permission: null},
                        {name: '打印拣货单&中通快递单', command:'w', permission: null},
                        {name: '打印货物图', command:'c', permission: null},
                        {name: '打印面单', command:'b', permission: null},
                        {name: '打印宅配面单', command:'p', permission: null},
                        {name: '打印货单&面单', command:'d', permission: null},
                        {name: '打印货单&货物图', command:'e', permission: null},
                        {name: '打印面单&货物图', command:'f', permission: null},
                        {name: '打印货单&面单&货物图', command:'g', permission: null},
                        {name: '打印实名单', command:'h', permission: null},
                        {name: '打印实名发票', command:'m', permission: null},
                        {name: '打印网购货物发票', command:'o', permission: ['stock:printWg']},
                        {name: '打印标签', command:'n', permission: ['stock:printTag']},
                        {name: '打印包裹单', command:'k', permission: null},
                        {name: '打印报关单', command:'i', permission: ['declare:print']},
                        {name: '自动打印实名发票单', command:'j', permission: null},
                        {name: '打印发票', command:'u', permission: ['stock:printFapiao']},
                        {name: '打印库签', command:'q', permission: null},
                    ],
                },
                {name: '打货单', icon: 'icon-dahuodan', 
                    permission: ['stock:OnlyOnePrint'], action: 'handlePrintOne', para: null,
                    children: null,
                },
                {name: '财务', icon: 'icon-caiwu', 
                    permission: ['finance:transitType','finance:transit','finance:proxy'], action: 'finance', para: null,
                    children: [
                        {name: '更新收费方式', command:'a', permission: ['finance:transitType']},
                        {name: '运费核算', command:'b', permission: ['finance:transit']},
                        {name: '回款核算', command:'c', permission: ['finance:proxy']},
                        {name: '理赔核算', command:'d', permission: ['finance:proxy']},
                        {name: '客诉件核算', command:'e', permission: ['finance:proxy']},
                    ],
                },
                {name: '物流记录', icon: 'icon-wuliujilu', 
                    permission: ['stock:trace'], action: 'doTrace', para: null,
                    children: null,
                },
                {name: '货态同步', icon: 'icon-huotaitongbu', 
                    permission: ['stock:doTrack'], action: 'doTrack', para: null,
                    children: null,
                },
                {name: '上传面单', icon: 'icon-shangchuangmiandan', 
                    permission: ['stock:pageImport'], action: 'pageImport', para: null,
                    children: null,
                },
                {name: '详情修改', icon: 'icon-xiangqingxiugai', 
                    permission: null, action: 'stockDetailEdit', para: null,
                    children: null,
                },
                {name: '快递货物', icon: 'icon-kuaidihuowu', 
                    permission: null, action: 'kuaidi', para: null,
                    children: null,
                },
                {name: '详情分拆', icon: 'icon-xiangqingchaifen', 
                    permission: ["stock:split"], action: 'stockSplit', para: null,
                    children: null,
                },
                {name: '货物转寄', icon: 'icon-huowuzhuanji', 
                    permission: ["stock:stockSelect"], action: 'selectStock', para: null,
                    children: null,
                },
                {name: '打包', icon: 'icon-dabao1', 
                    permission: ['stock:insertPkgCode','stock:autoPkgCode'], action: 'handleInsertPkgCode', para: null,
                    children: [
                        {name: '包号录入', command:'a', permission: ['stock:insertPkgCode']},
                        {name: '扫码打包', command:'b', permission: ['stock:autoPkgCode']},
                    ],
                },
                {name: '转运', icon: 'icon-zhuanyun', 
                    permission: ["stock:zhuanyunPkg","stock:pkgResend","stock:qijian","stock:zhuanyunRemark1","stock:noticeSend"], action: 'zhuanyunCommon', para: null,
                    children: [
                        {name: '集运转运', command:'a', permission: ["stock:zhuanyunPkg"]},
                        {name: '集运付费', command:'d', permission: ["stock:zhuanyunRemark1"]},
                        {name: '通知出货', command:'e', permission: ["stock:noticeSend"]},
                        {name: '包裹回寄', command:'b', permission: ["stock:pkgResend"]},
                        {name: '弃件处理', command:'c', permission: ["stock:qijianHandle"]},
                    ],
                },
                {name: '普特货', icon: 'icon-putebiaoji', 
                    permission: ["stock:updateGoodType"], action: 'handleGoodType', para: null,
                    children: [
                        {name: '标记特货', command:'a', permission: ["stock:updateGoodType"]},
                        {name: '标记普货', command:'b', permission: ["stock:updateGoodType"]},
                    ],
                },
                {name: '代打包', icon: 'icon-daidabao1', 
                    permission: ["stock:updatePkgType","stock:updatePkgTypeNew"], action: 'handlePkgType', para: null,
                    children: [
                        {name: '货运代打包', command:'a', permission: ["stock:updatePkgType"]},
                        {name: '代打包扫码登记', command:'b', permission: null},
                        {name: '代打包登记NEW', command:'c', permission: ["stock:updatePkgTypeNew"]},
                    ],
                },
                {name: '仓储贴单', icon: 'icon-cangchutiedan', 
                    permission: ["stock:updateOutType"], action: 'updateOutType', para: null,
                    children: null,
                },
                {name: '打包完成', icon: 'icon-dabaowancheng', 
                    permission: ["stock:finishPackage"], action: 'finishPakage', para: null,
                    children: null,
                },
                {name: '通知收件', icon: 'icon-tongzhishoujian', 
                    permission: ["stock:updateReceive"], action: 'receiveStock', para: null,
                    children: null,
                },
                {name: '虚拟揽收', icon: 'icon-xunilanshou', 
                    permission: ["stock:xuniReceive"], action: 'xuniReceiveStock', para: null,
                    children: null,
                },
                {name: '出货时间', icon: 'icon-chuhuoshijian', 
                    permission: ["stock:updateWeightTime"], action: 'updateWeightTime', para: null,
                    children: null,
                },
                {name: '取消订单', icon: 'icon-quxiaohuifu', 
                    permission: ["stock:cancel"], action: 'handleCancel', para: null,
                    children: [
                        {name: '取消', command:'a', permission: ["stock:cancel"]},
                        {name: '恢复', command:'b', permission: ["stock:cancel"]},
                    ],
                },
                {name: '贴单校对', icon: 'icon-tiedanxiaodui', 
                    permission: ["stock:codeCheck"], action: 'stockCodeCheck', para: null,
                    children: null,
                },
                {name: '扫码入库', icon: 'icon-saomaruku', 
                    permission: null, action: 'checkCodeInStore', para: null,
                    children: null,
                },
                {name: '入库出库', icon: 'icon-rukuchuku', 
                    permission: ["stock:qtyEdit"], action: 'stockQtyEdit', para: null,
                    children: null,
                },
                {name: '智能理货', icon: 'icon-zhinenglihuo', 
                    permission: ["stock:expSort","stock:stockSort"], action: 'zhineng', para: null,
                    children: [
                        {name: '快递分拣', command:'a', permission: ["stock:expSort"]},
                        {name: '库存分拣', command:'b', permission: ["stock:stockSort"]},
                    ],
                },
                {name: '快速找货', icon: 'icon-kuaisuzhaohuo', 
                    permission: ['stock:resendTicket','stock:detailTicket','stock:packageTicket'], action: 'handleFindTicket', para: null,
                    children: [
                        {name: '转寄找货单', command:'a', permission: ['stock:resendTicket']},
                        {name: '转运找货单', command:'b', permission: ['stock:detailTicket']},
                        {name: '待贴单包裹', command:'c', permission: ['stock:packageTicket']},
                    ],
                },
                {name: '扫码找货', icon: 'icon-saomazhaohuo', 
                    permission: null, action: 'scanCodeFun', para: null,
                    children: null,
                },
                {name: '出货核对', icon: 'icon-chuhuohedui', 
                    permission: ["stock:stockCheck"], action: 'stockCheck', para: null,
                    children: null,
                },
                {name: '货物交接', icon: 'icon-huowujiaojie', 
                    permission: null, action: 'handover', para: null,
                    children: null,
                },
                {name: '建单操作', icon: 'icon-jiandancaozuo', 
                    permission: ['stock:offlineCreate','stock:updateOutTypeFinish'], action: 'handleOfflineCreate', para: null,
                    children: [
                        {name: '快速建单', command:'a', permission: ['stock:offlineCreate']},
                        {name: '完成建单', command:'b', permission: ['stock:updateOutTypeFinish']},
                        {name: '重新建单', command:'c', permission: null},
                        {name: '录查询码', command:'e', permission: null},
                        {name: '同步线下面单', command:'d', permission: ['stock:offlineCreate']},
                    ],
                },
                {name: '分包加单', icon: 'icon-fenbaojiadan', 
                    permission: ["stock:addStockPkg"], action: 'addStockPkg', para: null,
                    children: null,
                },
                {name: '重量分摊', icon: 'icon-zhongliangfentan', 
                    permission: ["stock:weightShare"], action: 'weightShare', para: null,
                    children: null,
                },
                {name: '面单催收', icon: 'icon-miandancuishou', 
                    permission: ["stock:allowUpload"], action: 'handleAllowUpload', para: null,
                    children: [
                        {name: '通知上传面单', command:'a', permission: ["stock:allowUpload"]},
                        {name: '撤回通知', command:'b', permission: ["stock:allowUpload"]},
                    ],
                },
                {name: '异常通报', icon: 'icon-yichangtongbao', 
                    permission: ["stock:stockErrAdd"], action: 'stockErrAdd', para: null,
                    children: null,
                },
                {name: '同步Shopline订单', icon: 'icon-tongbudingdan',
                    permission: null, action: 'syncAllShoplineOrders', para: null,
                    children: null,
                },
                {name: '空白面单', icon: 'icon-kongbaimiandan', 
                    permission: ['stock:batchSendOrder'], action: 'doBatchSendOrder1', para: null,
                    children: null,
                },
                {name: '删除', icon: 'icon-piliangshanchu', 
                    permission: ["stock:deleteAll"], action: 'doDelete', para: null,
                    children: null,
                },
                {name: '单个删除', icon: 'icon-shanchu1', 
                    permission: ["stock:deleteOne"], action: 'doDeleteOne', para: null,
                    children: null,
                },
                {name: '批改卖家宅配', icon: 'icon-pilianggengxin1', 
                    permission: null, action: 'doUpdateCompany', para: null,
                    children: null,
                },
            ],
        }
      };
    },

    methods:{
        getDicts(key){
            return this.dicts[key];
        },
    }

}

export default dict;

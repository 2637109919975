import icons from '../assets/iconfont/iconfont.json';

// let server = "http://localhost:8080";
// let server = "http://127.0.0.1:8080"
let server = process.env.NODE_ENV === 'production' 
    ? ""
    : "http://localhost:8080";
let kit =  { 
    api:{
        server: server,
        docUrl: server+"/static/",
        companyInfo: server+"/api/p/companyInfo",
        companyImg: server+"/api/p/getCompanyImgId",
        login: server+"/am/auth/login",  //登录
        reg: server+"/am/auth/reg",  //注册
        logout: server+"/am/auth/quitLogin",  //注销
        code: server+"/api/p/code1",         //验证码ss
        shopeeDetail: server+"/api/p/shopeeDetail",         //产品详情 stockId, title
        shopeeDetail1: server+"/api/p/shopeeDetail1",         //产品详情 detailId
        barcode: server+"/api/p/barcode",         //条形码

        getExpCompany: server+"/am/exp-company-page/getExpCompany",

        langs: server+"/api/p/langs",
        resources: server+"/api/p/resources",
        addResources: server+"/api/p/addResources",
        imgView: server + "/api/p/file?id=",
        upload:server+"/am/attachment/upload",
        uploadChatImg:server+"/am/attachment/uploadChatImg",
        tplDownload: server+"/static/default-tpl.xlsx",
        jbDownload:server+"/static/Gprinter_2021.3_M-0.exe",
        chromeDownload:server+"/static/快捷采购插件.zip",
        chromeBdDownload:server+"/static/采购插件补丁.rar",
        dlDownloadNew:server+"/static/"+encodeURIComponent('NEW系列驱动程序.exe'),
        dlDownload:server+"/static/"+encodeURIComponent('得力条码打印机不带NEW系列驱动程序.exe'),
        docDownload:server+"/static/"+encodeURIComponent('7-11与全家门市信息.xlsx'),
        dept:{
            list: server+"/am/dept/list",
            listByDataPath:server+"/am/dept/listByDataPath",
            listByDataPathGrant:server+"/am/dept/listByDataPathGrant",
            create: server+"/am/dept/create",
            update: server+"/am/dept/update",
            updateCompany: server+"/am/dept/updateCompany",
            delete: server+"/am/dept/delete",
            deleteLogo: server+"/am/dept/deleteLogo",
            grantStore: server+"/am/dept/grantStore",
            getDetail: server+"/am/dept/getDetail",
        },
        menu: {
            all: server + "/am/menu/all",
            create: server + "/am/menu/create",
            update: server + "/am/menu/update",
            delete: server + "/am/menu/delete",
            toggle: server + "/am/menu/toggle", //根据角色获取菜单
            curr: server + "/am/menu/curr", //当前登录用户的菜单
        }, 
        permission: {
            list:server+"/am/permission/list" ,  //权限
            create:server+"/am/permission/create" , 
            update:server+"/am/permission/update" , 
            deletes:server+"/am/permission/deletes" , 
            toggle: server+"/am/permission/toggle",  //根据角色获取权限
        },  

        role:{
            list:server+"/am/role/list",
            create:server+"/am/role/create",
            update:server+"/am/role/update",
            delete:server+"/am/role/delete",
            menuUpdate: server + "/am/role/updateMenu", //分配菜单
            grantPermission: server + "/am/role/updatePermission", //分配权限
            grantCol: server + "/am/role/updateViewColumns", //分配字段权限
            btnsByCheck:server + "/am/role/btnsByCheck", 
            updateViewBtns:server + "/am/role/updateViewBtns", 
            updateRoleRefs:server + "/am/role/updateRoleRefs",  //分配引用角色
            roleRefs:server + "/am/role/roleRefs",  //获取引用角色
            updateViews:server + "/am/role/updateViews",  //分配视图
            viewUsers:server + "/am/role/viewUsers",  //获取视图引用
            copyRole:server + "/am/role/copyRole",//复制角色
        },

        lang:{
            languageList:server+"/am/lang/lanList",
            languageCreate:server+"/am/lang/lanCreate",
            languageUpdata:server+"/am/lang/lanUpdate",
            languageDelete:server+"/am/lang/lanDelete",

            resourceList:server+"/am/lang/resList",
            resourceUpdata:server+"/am/lang/resUpdate",
            resourceCreate:server+"/am/lang/resCreate",
            getRes:server+"/am/lang/getResources",
        },

        setting:{
            settingRoot: server+"/am/setting/root",
            settingList: server+"/am/setting/list",
            settingCreate: server+"/am/setting/create",
            settingUpdate: server+"/am/setting/update",
            settingDelete: server+"/am/setting/delete", 
        },
        
        user:{
            get: server+"/am/user/get", 
            getVipSet: server+"/am/user/getVipSet", 
            updateBuyType: server+"/am/user/updateBuyType", 
            list: server+"/am/user/list", 
            create: server+"/am/user/create", 
            update: server+"/am/user/update", 
            delete: server+"/am/user/delete",
            grantData: server+"/am/user/grantData",
            datapath: server+"/am/user/datapath",
            updateSimple: server+"/am/user/updateSimple",  //更新用户基本信息
            updatePhoto: server+"/am/user/updatePhoto",
            friends: server+"/am/user/friends", //获取好友列表
            search: server+"/am/user/search",   //查找用户
            addFriend: server+"/am/user/addFriend",   //添加好友
            delFriend: server+"/am/user/delFriend",   //删除好友
            remarkFriend: server+"/am/user/remarkFriend",   //修改好友备注
            changePwd:server+"/am/user/changePwd",   //修改密码
            comTest: server+"/static/res/COMTest.zip",
            settingUpdate: server+"/am/user/setting/update",
            settingList: server+"/am/user/setting/list",
            ecConfig: server+"/am/user/setting/ecConfig",
            tpConfig: server+"/am/user/setting/tpConfig",
            pwConfig:server+"/am/user/setting/pwConfig",//打印提醒
            configs: server+"/am/user/setting/config",
            runAs:server+"/am/user/runAs",
            disabled:server+"/am/user/disable",
            checkVipKey:server+"/am/user/checkVipKey",
            reloadUser: server+"/am/user/reloadPermission",
            editStatus: server+"/am/user/editStatus",
            updateToken: server+"/am/user/updateToken",
            updatePageTag: server+"/am/user/updatePageTag",
            isStrongAuth: server+"/am/user/isStrongAuth",
            strongAuth: server+"/am/user/strongAuth",
        },
        wallet: {
            checkLoginPwd: server+"/am/wallet/checkLoginPwd", //检查登录密码
            checkWalletPwd: server+"/am/wallet/checkWalletPwd", //检查提现密码
            checkCompanyWord: server+"/am/wallet/checkCompanyWord", //检查开户公司结算口令
            checkCompanyPwd: server+"/am/wallet/checkCompanyPwd", //检查开户公司结算密码
            details: server+"/am/wallet/details", //获取流水详情
            get: server+"/am/wallet/balance", //获取钱包信息
            list: server+"/am/wallet/list", //获取所有
            listForDetail: server+"/am/wallet/listForDetail", //费用清单list
            create: server+"/am/wallet/create",//开户
            updateCompany: server+"/am/wallet/updateCompany",//更新开户公司
            updateLoan: server+"/am/wallet/updateLoan",//更新信用额度
            updatePoint: server+"/am/wallet/updatePoint",//更新积分
            updateWalletPassword: server+"/am/wallet/updateWalletPassword",
            cashIn: server+"/am/wallet/cashIn", //充值
            cashOut: server+"/am/wallet/cashOut", //提现
            finish: server+"/am/wallet/finish", //完成
            cancel: server+"/am/wallet/cancel", //取消
            doImport: server+"/am/wallet/doImport", //导入
            doImportRemark: server+"/am/wallet/importRemark", //导入
            importTransport: server+"/am/wallet/importTransport", //导入运费
            delete: server+"/am/wallet/delete", //
            remark: server+"/am/wallet/remark", //备注
            findStocks: server+"/am/wallet/findStocks",
            deleteWallet: server+"/am/wallet/deleteWallet",
            doFreight: server+"/am/wallet/doFreight",
            showTip: server+"/am/wallet/showTip",
            updateRemark1: server+"/am/wallet/updateRemark1",
            updatePayStatus: server+"/am/wallet/updatePayStatus",
            updateWalletDetailImg: server+"/am/wallet/updateWalletDetailImg",
            getOwnerCompanyIds: server+"/am/wallet/getOwnerCompanyIds",
        },
        pointDetail:{
            list: server+"/am/point-detail/list", 
        },
        coupon:{
            list: server+"/am/coupon/list", 
            create: server+"/am/coupon/create", 
        },
        vip: {
            listView: server+"/am/vip/listView", //获取vip列表
            prices: server+"/am/vip/prices", //获取vip价格
            orders: server+"/am/vip/orders", //获取会员支付订单
            query: server+"/am/vip/queryPayResult", //查询支付进度
            queryFree: server+"/am/vip/queryPayResultFree", //查询支付进度
            deleted: server+"/am/vip/delete", //删除
            checkBuy: server+"/am/vip/checkBuy", //检测是否能够购买
            checkBuyEnable: server+"/am/vip/checkBuyEnable",
            checkOrderStatus:server+"/am/vip/checkOrderStatus", 
            cancelOrder:server+"/am/vip/cancelOrder", 
            buy: server+"/am/vip/buy", //购买会员
            jsonSetting: server+"/am/vip/jsonSetting", //购买会员
            setting: server+"/am/vip/setting",
        },
        offer: {
            list: server+"/am/offer/list", //获取报价设置列表
            detail: server+"/am/offer/detail", //获取报价设置详情
            create: server+"/am/offer/create", //创建报价设置
            updateRemark: server+"/am/offer/updateRemark", //批量更新备注
            updateUsed: server+"/am/offer/updateUsed",//批量调用
            copyOf: server+"/am/offer/copyOf", //复制报价到指定的客户
            deletes: server+"/am/offer/deletes", //删除指定的报价设置
            edit: server+"/am/offer/edit", //编辑报价
            resetTransit: server+"/am/offer/resetTransit", //运费设置
            resetDelivery: server+"/am/offer/resetDelivery", //交派费设置
            resetPkg: server+"/am/offer/resetPkg", //交派费设置
            resetPasteBill: server+"/am/offer/resetPasteBill", //贴单费设置
            resetProxy: server+"/am/offer/resetProxy", //贴单费设置
            resetRate: server+"/am/offer/resetRate", //汇率设置
        },
        message: {
            list: server+"/am/msg/list",                //消息列表
            deletes: server+"/am/msg/deletes",          //删除消息
            totalUnread: server+"/am/msg/totalUnread",  //获取未读消息总数
            send: server+"/am/msg/send",                //发送消息
            totalGroupUnread: server+"/am/msg/totalGroupUnread", //根据用户汇总消息总数
            userList: server+"/am/msg/userList",        //获取用户的获取消息列表
            markRead: server+"/am/msg/markRead",        //标记为已读
            deleteByUser: server+"/am/msg/deleteByUser",         //根据用户删除消息
        },
        workorder: {
            list: server+"/am/workorder/list",                //工单列表
            detail: server+"/am/workorder/detail",
            create: server+"/am/workorder/create",
            createbatch: server+"/am/workorder/createbatch",
            delete: server+"/am/workorder/delete",
            comment: server+"/am/workorder/comment",
            listcount: server+"/am/workorder/listcount",
            finish: server+"/am/workorder/finish",
            servicelist: server+"/am/workorder/servicelist",
            typelist: server+"/am/workorder/typelist",
            otherservicelist: server+"/am/workorder/otherservicelist",
            process: server+"/am/workorder/process",
            typeChange: server+"/am/workorder/typeChange",
            typeDescChange: server+"/am/workorder/typeDescChange",
            levelChange: server+"/am/workorder/levelChange",
            change: server+"/am/workorder/change",
            workOrderCount: server+"/am/workorder/workOrderCount",
            workOrderCountList: server+"/am/workorder/workOrderCountList",
        },
        workordermsg: {
            list: server+"/am/workordermsg/list",                //消息列表
            deletes: server+"/am/workordermsg/deletes",          //删除消息
            totalUnread: server+"/am/workordermsg/totalUnread",  //获取未读消息总数
            send: server+"/am/workordermsg/send",                //发送消息
            listbyorder: server+"/am/workordermsg/listByOrder",        //获取用户的获取消息列表
            markRead: server+"/am/workordermsg/markRead",        //标记为已读
            deleteByUser: server+"/am/workordermsg/deleteByUser",         //根据用户删除消息
        },
        viewSetting: {
            get: server+"/am/view-setting/get", 
            list: server+"/am/view-setting/list", 
            listByType: server+"/am/view-setting/listByType", 
            tables: server+"/am/view-setting/tablesByLogin", 
            checked: server+"/am/view-setting/tablesByCheck", 
            create: server+"/am/view-setting/create", 
            update: server+"/am/view-setting/update", 
            delete: server+"/am/view-setting/delete", 
            seq: server+"/am/view-setting/sort", 
            defaulted: server+"/am/view-setting/defaulted", 
            defaultView: server+"/am/view-setting/defaultView", 
            setting: server+"/am/view-setting/setting", 
        },
        attachment:{
            list: server+"/am/attachment/list", 
            delete: server+"/am/attachment/delete", 
            upload: server+"/am/attachment/upload", 
        },
        history:{
            list: server + "/am/history/list",
            loginlist: server + "/am/history/loginlist",
        },
        notice:{
            get:server+"/am/notice/get", 
            send:server+"/am/notice/send", 
            myList:server+"/am/notice/myList", 
            list:server+"/am/notice/list", 
            myDelete:server+"/am/notice/myDelete", 
            delete:server+"/am/notice/delete", 
            updateStatus:server+"/am/notice/updateStatus", 
            attachment:server+"/am/notice/getFileList",
            warnList:server+"/am/notice/remindList",
            deleteShow:server+"/am/notice/deleteShow",
            findUserByDeptId:server+"/am/notice/findUserByDeptId",
            findUserByNames:server+"/am/notice/findUserByNames",
            findUsersByNoticeId:server+"/am/notice/findUsersByNoticeId",
        },
        video:{
            list:server+"/am/video/list", 
            add:server+"/am/video/add", 
            edit:server+"/am/video/edit", 
            delete:server+"/am/video/delete", 
        },  
        stock:{
            getStockByIds:server+"/am/stock/getStockByIds", 
            getStockById:server+"/am/stock/getStockById", 
            getZhuanyunByIds:server+"/am/stock/getZhuanyunByIds", 
            getWeightByIds:server+"/am/stock/getWeightByIds", 
            getStockDetailsByIds:server+"/am/stock/getStockDetailsByIds", 
            list:server+"/am/stock/list", 
            listTotal: server+"/am/stock/listTotal",
            detailList:server+"/am/stock/detailList", 
            expressOrderList:server+"/am/stock/expressOrderList", 
            expOrderAdd:server+"/am/stock/expOrderAdd", 
            sortExpOrderAdd:server+"/am/stock/sortExpOrderAdd", 
            expOrderEdit:server+"/am/stock/expOrderEdit",
            expOrderDelete:server+"/am/stock/expOrderDelete",
            reportList:server+"/am/stock/reportList", 
            reportListForSj:server+"/am/stock/reportListForSj", 
            checkHistList:server+"/am/stock/checkHistList", 
            get:server+"/am/stock/get",
            getStore: server+"/am/stock/getStore",
            create:server+"/am/stock/create",
            update:server+"/am/stock/update",
            delete:server+"/am/stock/delete",
            deleteOne:server+"/am/stock/deleteOne",
            configList: server+"/am/stock/import/configList",
            configAdd: server+"/am/stock/import/configAdd",
            configDelete: server+"/am/stock/import/configDelete",
            doImport: server+"/am/stock/import/doImport",
            detail:server+"/am/stock/detail",
            checkUploadPermission:server+"/am/stock/checkUploadPermission",
            pageImport:server+"/am/stock/pageImport",
            pageImport1:server+"/am/stock/pageImport1",
            onlyPageImport:server+"/am/stock/onlyPageImport",
            batchUpdate: server + "/am/stock/batchUpdate",
            updateColumn:server + "/am/stock/updateColumn",
            inout: server + "/am/stock/inout", //出仓入仓
            checkSaveBatchPkg: server + "/am/stock/inout/checkSaveBatchPkg",  //检测袋号
            getPrintData: server + "/am/stock/inout/print", //获取打印数据
            getPrintDataZp:server + "/am/stock/inout/printZp",
            getStockSelect:server + "/am/stock/getStockSelect",
            stockAddPkg:server + "/am/stock/stockAddPkg",
            getStockAndDetail:server + "/am/stock/getStockAndDetail",
            stockErrorAdd:server + "/am/stock/stockErrorAdd",
            weightShare:server + "/am/stock/weightShare",
            allowUpload:server + "/am/stock/allowUpload",
            doZhuanYun:server + "/am/stock/doZhuanYun",
            updateError: server + "/am/stock/updateError",
            //物流跟踪详情
            traces: server + "/am/stock/traces",
            tracesNew: server + "/am/stock/tracesNew",
            //线下建单
            offlineCreate: server + "/am/stock/offlineCreate",
            getPkgCodes:server + "/am/stock/getPkgCodes",
            updatePkgCode:server + "/am/stock/updatePkgCode",
            autoSavePkgCode:server + "/am/stock/autoSavePkgCode",
            getPrintFindData:server + "/am/stock/print/getPrintFind",
            getPrintPkgCodeData:server + "/am/stock/print/getPrintPkgCodeData",
            findPkgPage:server + "/am/stock/print/findPrintPkgPage",
            finishPackage:server + "/am/stock/finishPackage",
            doTrack:server + "/am/stock/doTrack",//货态同步
            doTrackExpress:server + "/am/stock/doTrackExpress",//快递同步
            doSaveInnerExpCode:server + "/am/stock/doSaveInnerExpCode",
            updateRemark:server + "/am/stock/updateRemark",//更新备注
            updateRemarks:server + "/am/stock/updateRemarks",
            updateDetailCodes:server + "/am/stock/updateDetailCodes",//批量更新详情货单号和架位号
            batchSendOrder:server + "/am/stock/batchSendOrderOld",//一键发货
            batchSendOrder1:server + "/am/stock/batchSendOrder1",//空白面单
            batchSendOrderNew:server + "/am/stock/batchSendOrderNew",//一键发货新逻辑
            expressSorting:server + "/am/stock/expressSorting",//快递分拣
            stockSorting:server + "/am/stock/stockSorting",//库存分拣
            getPici:server + "/am/stock/getPici",//获取批次
            getPiciByDate:server + "/am/stock/getPiciByDate",//根据日期获取批次
            getPkgCodeByIds:server + "/am/stock/getPkgCodeByIds",//根据库存ID获取袋号
            printPkgTicket:server + "/am/stock/print/printPkgTicket",
            printPkgTicketAuto:server + "/am/stock/print/printPkgTicketAuto",
            printPkgTicketAutoP:server + "/am/stock/print/printPkgTicketAutoP",
            printRealTicket:server + "/am/stock/print/printRealTicket",
            stockSplit:server + "/am/stock/stockSplit",//详情分拆
            detailCreate:server + "/am/stock/detailCreate",//详情分拆-添加详情
            getErrList:server + "/am/stock/getErrList",
            getFastPrintData:server + "/am/stock/print/fastPrint",
            getZhuanyunData:server + "/am/stock/getZhuanyunData",//获取集运转运数据
            getQijianData:server + "/am/stock/getQijianData",//获取弃件数据
            doQijian:server + "/am/stock/doQijian",
            stockCheckCode:server + "/am/stock/stockCheckCode",//条码校对
            initCode:server + "/am/stock/initCode",
            createCode:server + "/am/stock/createCode",
            createStore:server + "/am/stock/createStore",
            checkCodes:server + "/am/stock/checkCodes",
            updateDetailReport:server + "/am/stock/updateDetailReport",
            detailThumb:server + "/am/stock/detailThumb",
			qrupp:server + "/am/stock/qrUpp", //二维码上架
			printCodeAsBarCode:server + "/am/stock/print/printCodeAsBarCode", //将货单号打印成条形码
            printExpCodeAsBarCode:server + "/am/stock/print/printExpCodeAsBarCode", 
            printShopNameAsBarCode:server + "/am/stock/print/printShopNameAsBarCode", //将货单号打印成条形码
            printBagCodeAsBarcode:server + "/am/stock/print/printBagCodeAsBarcode", //将货包号打印成条形码
            getDetailEditList:server+"/am/stock/getDetailEditList",//详情修改list
            updateDetail:server+"/am/stock/updateDetail",//批量修改详情
            acceptReport:server+"/am/stock/acceptReport",//报单受理
            detailBuyInputCode:server+"/am/stock/detailBuyInputCode",//货物采购录入快递单号
            detailBuyInputShelfCode:server+"/am/stock/detailBuyInputShelfCode",//货物采购录入架位号
            updateMark:server+"/am/stock/updateMark",//货物采购-点到
            cancelReport:server+"/am/stock/cancelReport",//撤销受理
            detailBuyViewList:server+"/am/stock/detailBuyViewList",
            deleteBatchById:server+"/am/stock/deleteBatchById",
            updateRemark1:server+"/am/stock/updateRemark1",
            zhuanyunCharge:server+"/am/stock/zhuanyunCharge",
            stockQtyEditList:server+"/am/stock/stockQtyEditList",//入库出库list
            updateQty:server+"/am/stock/updateQty",//入库出库更新
            selectStatusById:server+"/am/stock/selectStatusById",
            getOfflinePage:server+"/am/stock/getOfflinePage",
            getOfflineExpCode:server+"/am/stock/getOfflineExpCode",
            checkCode:server+"/am/stock/checkCode",
            expCodeSave:server+"/am/stock/expCodeSave",
            packSave:server+"/am/stock/packSave",
            noticeSend:server+"/am/stock/noticeSend",
            searchKuaidiCode:server+"/am/stock/searchKuaidiCode",
            updateKuaidi:server+"/am/stock/updateKuaidi",
            makeBatchCode:server+"/am/stock/makeBatchCode",
            stockHandover:server+"/am/stock/stockHandover",
            xuniReceiveStock:server+"/am/stock/xuniReceiveStock",
            stockDetailTongji:server+"/am/stock/stockDetailTongji",//库存管理统计
            editDetailReady:server+"/am/stock/editDetailReady",
            editDetailLeast:server+"/am/stock/editDetailLeast",
            editStockStatus:server+"/am/stock/editStockStatus",
            editDetailBuyLink:server+"/am/stock/editDetailBuyLink",
            editDetailRemark:server+"/am/stock/editDetailRemark",
            editStockCreated:server+"/am/stock/editStockCreated",
            checkCodeInStore:server+"/am/stock/checkCodeInStore",//扫码入库
            getDetailByInnerCode:server+"/am/stock/getDetailByInnerCode",
            checkWalletStatus:server+"/am/stock/checkWalletStatus",
            saveVideoImg:server+"/am/stock/saveVideoImg",
            doUpdateCompany:server+"/am/stock/doUpdateCompany",
            saveBatch:server+"/am/stock/batch/save",//保存批次
            getBatchList:server+"/am/stock/batch/getPici",//查询批次
            pagePicExport:server+"/am/stock/pagePicExport",//导出面单
        },
        stockDetail: {
            listByStock:server + "/am/stock/detail/listByStock",//预览详情第一版
            listByStock1:server + "/am/stock/detail/listByStock1",//预览详情第二版
            updateDetailCode:server + "/am/stock/detail/updateDetailCode",//更新详情货单号和架位号
            updateDetailVariationSku:server + "/am/stock/detail/updateDetailVariationSku",  //更新货号和SKU
            updateDetailCost:server + "/am/stock/detail/updateDetailCost", //更新详情成本
            updateDetailStore:server + "/am/stock/detail/updateDetailStore",  //更新详情到货仓库
            deleteInnerCode:server + "/am/stock/detail/deleteInnerCode",  //清除快递单号和架位号
            updateDetailMark:server + "/am/stock/detail/updateDetailMark",//更新详情，点到
            deleteDetail:server + "/am/stock/detail/deleteDetail", //删除详情
            openBuyLink:server + "/am/stock/detail/openBuyLink",       //搜索采购链接表，查找对应链接
            openBuyLinks:server + "/am/stock/detail/openBuyLinks",     //批量搜索采购链接表，查找对应链接
            updateDetailPurchaseCode:server + "/am/stock/detail/updateDetailPurchaseCode",  //更新采购单号
            updateDetailPurchaseCodes:server + "/am/stock/detail/updateDetailPurchaseCodes", //批量更新采购单号
            rebackDetailPurchaseCode:server + "/am/stock/detail/rebackDetailPurchaseCode",   //撤销已采购单号
			createDetail:server + "/am/stock/detail/createDetail",  //添加一条详情
            getDetail:server + "/am/stock/detail/getDetail",
            getDetailList:server + "/am/stock/detail/getDetailList",
            detailBuyList:server+"/am/stock/detail/detailBuyList",//货物采购list
        },
        finance: {
            transitTypeList:server+"/am/finance/transitTypeList",//收费方式list
            updateTransitType:server+"/am/finance/updateTransitType",//更新收费方式
            computeTransit:server+"/am/finance/computeTransit",//运费计算
            transitPercent:server+"/am/finance/transitPercent",//运费计算进度
            transitComputeFinish:server+"/am/finance/transitComputeFinish",//标记完成
            computeProxy:server+"/am/finance/computeProxy",//回款计算
            proxyPercent:server+"/am/finance/proxyPercent",//回款计算进度
            proxyComputeFinish:server+"/am/finance/proxyComputeFinish",//标记完成
            computeLipei:server+"/am/finance/computeLipei",//理赔计算
            lipeiPercent:server+"/am/finance/lipeiPercent",//理赔计算进度
            lipeiComputeFinish:server+"/am/finance/lipeiComputeFinish",//标记完成
            computeKesu:server+"/am/finance/computeKesu",//客诉件计算
            kesuPercent:server+"/am/finance/kesuPercent",//客诉件计算进度
            kesuComputeFinish:server+"/am/finance/kesuComputeFinish",//客诉件标记完成
        },
        resend: {
            preview: server + "/am/stock/resend/preview", //与匹配转寄，
            search: server + "/am/stock/resend/search",  //搜索
            save: server + "/am/stock/resend/save",  //搜索
            findExps: server + "/am/stock/resend/findExps",  //搜索
            searchSku: server + "/am/stock/resend/searchSku",
        },
        store: {
            get: server+"/am/store/get",
            list: server+"/am/store/list",
            create: server+"/am/store/create",
            update: server+"/am/store/update",
            delete: server+"/am/store/delete",
            listUsed: server+"/am/store/listByUsed",
            listAll:server+"/am/store/listAll",
        },
        company: {
            list: server+"/am/company/list",
            listByDataPath: server+"/am/company/listByDataPath",
            create: server+"/am/company/create",
            update: server+"/am/company/update",
            delete: server+"/am/company/delete",
        },
        shop: {
            get: server+"/am/shop/get",
            list: server+"/am/shop/list",
            delete: server+"/am/shop/delete",
            bind: server+"/am/shop/bind",
            syncShop: server+"/am/shop/sync",
            editName: server + "/am/shop/editName",
            syncOrder: server + "/am/shop/syncOrder",
            checkShopNum: server + "/am/shop/checkShopNum",
            getShopQty: server + "/am/shop/getShopQty",
            getShopQtyNum: server + "/am/shop/getShopQtyNum",
            shopQtyRefresh: server + "/am/shop/shopQtyRefresh",
            change:server+"/am/shop/change",
            refreshToken:server+"/am/shop/refreshToken",
            getLink:server+"/am/shop/getLink",
        },
        simpleProductName:{
            list:server+"/am/simple-product-name/list",
            create:server+"/am/simple-product-name/create",
            update:server+"/am/simple-product-name/update",
            delete:server+"/am/simple-product-name/delete",
        },
        updateColumn:{
            stockCheck:server + "/am/column/stockCheck",
            stockCheckUpdateStatus:server + "/am/column/stockCheckUpdateStatus",
            updateGoodType:server + "/am/column/updateGoodType",
            updatePkgType:server + "/am/column/updatePkgType",//货运代打包
            updateOutType:server + "/am/column/updateOutType",//仓储贴单
            updateReceive:server + "/am/column/updateReceive",//通知收件
            updateCancel:server + "/am/column/cancel",//取消订单
            updateOutTypeFinish:server + "/am/column/updateOutTypeFinish",//完成建单
            updateWeightTime:server + "/am/column/updateWeightTime",
            updatePackType:server + "/am/column/updatePackType",
            updateStatus:server + "/am/column/updateStatus",
        },
        export:{
            exportExp:server + "/am/export/exportExp",//下载清关资料
            exportHangkong:server + "/am/export/exportHangkong",//下载航空资料
            exportHeimao:server + "/am/export/exportHeimao",//黑猫派件资料
            exportTihuo:server + "/am/export/exportTihuo",//提货单
            exportJiandan:server + "/am/export/exportJiandan",//建单资料
            tongji:server + "/am/export/tongji",//出货统计
            hqList:server + "/am/export/hqList",//航空清关
            zhaohuoTicket:server + "/am/export/zhaohuoTicket",//转运找货单
            exportDetail:server + "/am/export/exportDetail",//导出详情
            exportBuyDetail:server + "/am/export/exportBuyDetail",//导出详情
            exportResendCost:server + "/am/export/exportResendCost",//导出转寄成本
            exportStatus:server + "/am/export/exportStatus",//导出物流记录
            exportWalletDetailList:server + "/am/export/exportWalletDetailList",//导出运费账单
            exportHistSave:server + "/am/export/exportHistSave",
            getStockReceives:server+"/am/export/getStockReceives",
            exportStockList:server+"/am/export/stock",
        },
        declare: {  //清关资料
            list: server + "/am/declare/list", //查看列表
            deletes: server+ "/am/declare/deletes", //删除
            creates: server + "/am/declare/creates", //添加
            lock: server + "/am/declare/lock", //添加
            print: server + "/am/declare/print" //打印
        },
        purchase: {  //采购链接
            list: server + "/am/purchase/list", //查看列表
            delete: server + "/am/purchase/delete",
            create: server + "/am/purchase/create",
            update: server + "/am/purchase/update",
            updates: server + "/am/purchase/updates",
            imports: server + "/am/purchase/imports",
            updatePurchaseRemark5: server + "/am/purchase/updatePurchaseRemark5",
            updatePurchaseRemark6: server + "/am/purchase/updatePurchaseRemark6",
        },
        category: {  
            list: server + "/am/category/list", //查看列表
            delete: server + "/am/category/delete",
            create: server + "/am/category/create",
            update: server + "/am/category/update",
            imports: server + "/am/category/imports",
            batchUpdate: server + "/am/category/batchUpdate",
        },
        site:{
            list: server + "/am/site/list", //查看列表
            create: server + "/am/site/create",
            update: server + "/am/site/update",
            delete: server + "/am/site/delete",
            addressList: server + "/am/site/addressList",
            addressCreate: server + "/am/site/addressCreate",
            addressUpdate: server + "/am/site/addressUpdate",
            addressDelete: server + "/am/site/addressDelete",
        },
        expCompanyPage:{
            list: server + "/am/exp-company-page/list", //查看列表
            create: server + "/am/exp-company-page/create",
            update: server + "/am/exp-company-page/update",
            delete: server + "/am/exp-company-page/delete",
            getConfig: server + "/am/exp-company-page/getConfig",
            createConfig: server + "/am/exp-company-page/createConfig",
            updateConfig: server + "/am/exp-company-page/updateConfig",
        },
        printSetting:{
            listTemplate: server + "/am/print-setting/listTemplate", //获取模板列表
            template: server + "/am/print-setting/template", //获取模板具体信息
            updateTemplate: server + "/am/print-setting/updateTemplate", //更新打印模板
            createTemplate: server + "/am/print-setting/createTemplate", //新增打印模板
            deleteTemplate: server + "/am/print-setting/deleteTemplate", //删除打印模板
        },
        shopline:{
            syncMyOrders: server + "/am/shopline/syncMyOrders",
            syncUserOrders: server + "/am/shopline/syncUserOrders",
            syncAllOrders: server + "/am/shopline/syncAllOrders",
        }
    },
    rebuildQuery: function(query, dateFields){
        var param = {};
        for(var key in query){
            if(key === 'queries') continue;

            var value = query[key];
             
            if(value===null) {
                continue;
            }else if(value === ""){
                continue;
            }else if(this.isArray(value)){
                if(!dateFields || dateFields.indexOf(key)>-1){
                    try{
                        if(value[0])
                            param["start"+key]=value[0];
                        if(value[1])
                            param["end"+key]=value[1];
                    }catch(err){
                        console.error(err);
                    }
                }else if(value.length>0){
                    param[key] = value;
                }
            }else{
                param[key]=value;
            }
        }
        return param;
    },
    clearQuery: function(query){
        for(var key in query){
            if(key === 'queries') continue;
            if(key=='start') {
                query[key] = 0;
            }else if(key == 'length'){
                continue;
            }else if(key == 'pageIndex')
                query[key] = 1;
            else {
                var val = query[key];
                if(this.isArray(val) && val.length>0){
                    query[key].splice(0, query[key].length);
                }else{
                    query[key] = null;
                }
            }
        }
    },
    isArray : function(arr){
        return Object.prototype.toString.call(arr) === '[object Array]';
    },
    uuuid: function(){
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
    
        var uuid = s.join("");
        return uuid.replace("-", "");
    },
    buildTree(menus, sort){
        var map = {};
        menus.forEach(m => {
            if(m.parentId === null){
                m.parentId = 0;
            }
            map[m.id] = m;
        });
        
        // let minParent = Math.min(...menus.map(e => e.parentId));
        
        for(let key in map){
            var m = map[key];
            if(m.parentId){ 
                var parent = map[m.parentId];
                if(parent){
                    if(!parent['children']){
                        parent['children'] = [];
                    }

                    parent.children.push(m);
                    m.parent = parent;
                }
            }
        }

        var ms = [];

        for(let key in map){
            let m = map[key];
            if(m.parent == null){
                ms.push(m);
            }
        }
        
        if(sort){
            this.sortTree(ms);
        }
        //ms.sort((a, b) => (b.seq || 0) - (a.seq || 0));

        return ms;
    },
    sortTree(ms){
        ms.sort((a, b) => (a.seq || 0) - (b.seq || 0));
        ms.forEach(m => {
            if(m['children'] && m.children.length>0){
                this.sortTree(m['children']);
            }
        });
    },
    icons: icons.glyphs.map(i => {
            i.font_class = icons.css_prefix_text+i.font_class;
            return i;
        }
    ),
    //格式化日期为： yyyy-MM-dd
    fmtDate(date){
        let m = date.getMonth()+1; 
        let d = date.getDate();
        return date.getFullYear() + "-" + (m<10?('0'+m): m) + "-" + (d<10?('0'+d): d);
    },
    //格式化日期为： yyyy-MM-dd HH:mm:ss
    fmtDateTime(date){
        let m = date.getMonth()+1; 
        let d = date.getDate();
        let h = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();

        return date.getFullYear() + "-" + (m<10?('0'+m): m) + "-" + (d<10?('0'+d): d) 
            + " "+ (h<10? ('0'+h): h)
            + ":"+ (min<10?('0'+min):min)
            + ":"+ (sec<10?('0'+sec):sec);

    },
    getQueryValue(queryName) {
        var query = decodeURI(window.location.search.substring(1));
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == queryName) { return pair[1]; }
        }
        return null;
    },
    delCookie(name){
        var exp = new Date();
        exp.setTime(exp.getTime() - 1);
        var cval=this.getCookie(name);
        if(cval!=null)
            document.cookie= name + "="+cval+";expires="+exp.toGMTString()+";path=/";
    },
    setCookie(name,value) {
        var Days = 30;
        var exp = new Date();
        exp.setTime(exp.getTime() + Days*24*60*60*1000);
        document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString()+";path=/";
    },
    getCookie(name){
        var reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
        let arr = document.cookie.match(reg);
        if(arr)
            return unescape(arr[2]);
        else
            return null;
    },
    strongAuth(func, page) {
        var _this = this;
        page.$axios.post(_this.api.user.isStrongAuth, "{}").then((res) => {
            if (res.status && res.data) {
                func();
            } else {
                _this.strongAuthData.closeEvent = function (type) {
                    page.strongPwdVisiable = false;
                    if (type) {
                        func();
                    }
                };
                page.strongPwdVisiable = true;
            }
        });
    },
    strongAuthData: {
        closeEvent: null,
        reg: /[^0-9a-zA-Z~!@#$%^&*\-_.]/
    }
            
};

kit.install = function(Vue, options) {
    Vue.kit = kit;
    window.kit = kit;
    Object.defineProperties(Vue.prototype, {
      kit: {
        get() {
          return kit;
        }
      },
      $kit: {
        get() {
          return kit;
        }
      },
    });
  };

export default kit;

<template>
<div class="notice-wrap" v-if="notice">
    <h2 style="text-align:center;">{{notice.title}}</h2>
    <div style="text-align:center;">{{notice.created}}</div>
    <div style="text-align:center;" v-if="notice.attchs && notice.attchs.length>0">{{msg("附件")}}： 
        <el-link style="margin-right:20px;" type="primary" v-for="(file , idx) in notice.attchs" :key="idx" :href="fileUrl+file.attachmentId">{{file.filename}}</el-link>
    </div>
    <div style="padding: 30px 0px"> 
        <span v-html="notice.content"></span>
    </div>

    <div style="text-align:center;">
        <el-button type="primary" size="mini" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>
</div>
</template>

<script>
export default {
  name: 'NoticeView',
  data(){
    return{
        fileUrl:this.$kit.api.imgView,
        notice: null,
    }
  }, 
  methods:{
      init(id){
          this.notice = null;
          this.fileList = [];
          this.$axios.get(this.$kit.api.notice.get+"?id="+id)
            .then((result) => {
                if(result.status){
                    this.notice = result.data;
                }else{
                    this.$message(result.msg);
                }
            });
      },
  }
}
</script>

</style>

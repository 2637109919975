import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMinScreen: false,
    docHeight: 300,
    loginId: null,
    docWidth: null,
    isQueryExpend: true,
    companyInfo: null,
    activeMenus: null,
    stockIds: null,
    admin: null,
  },
  mutations: {
    isMinScreen (state, val) {
      state.isMinScreen = val; 
    },
    docHeight (state, val) {
      state.docHeight = val; 
    },
    docWidth (state, val) {
      state.docWidth = val; 
    },
    isQueryExpend (state, val) {
      state.isQueryExpend = val; 
    }, 
    companyInfo(state, val){
      state.companyInfo = val;
    },
    activeMenus(state, val){
      state.activeMenus = val;
    },
    stockIds(state, val){
      state.stockIds = val;
    },
    loginId(state, val){
      state.loginId = val;
    },
    admin(state, val){
      state.admin = val;
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div>
    <div class="cmplex-filter">
      <div class="f-type">
        <el-radio-group v-model="option.data.type">
            <el-radio name="fType" label="has">包含</el-radio>
            <el-radio name="fType" label="eq">等于</el-radio>
            <el-radio name="fType" label="gt">大于</el-radio>
            <el-radio name="fType" label="lt">小于</el-radio>
        </el-radio-group>
      </div>
      <div class="f-name" style="margin: 10px 0px;">
        <el-input v-model="option.data.name" placeholder="输入值" @input="changeOptionEvent()" size="minie">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <div class="f-iscase">
        <el-checkbox v-model="option.data.isCase">不区分大小写</el-checkbox>
      </div>
      <div class="f-footer" style="margin:10px 0px;">
        <el-button type="primary" size="mini" @click="confirmEvent">确认</el-button>
        <el-button type="plain" size="mini" @click="resetEvent">重置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterComplex',
  props: {
    params: Object
  },
  data () {
    return {
      size: 'mini', // 被所有子组件继承 size
      column: null,
      option: null
    }
  },
  created () {
    // filters 可以配置多个，实际只用一个就可以满足需求了
    const { column } = this.params
    const option = column.filters[0]
    this.column = column
    this.option = option
  },
  methods: {
    changeOptionEvent () {
      const { params, option } = this
      const { $panel } = params
      const checked = !!option.data.name
      $panel.changeOption(null, checked, option)
    },
    confirmEvent () {
      const { $panel } = this.params
      $panel.confirmFilter()
    },
    resetEvent () {
      const { $panel } = this.params
      $panel.resetFilter()
    }
  }
}
</script>

<style scoped>
.cmplex-filter {
  width: 260px;
  padding: 0 8px;
}
.cmplex-filter .f-type {
  margin: 10px 0px;
}
.cmplex-filter .f-iscase {
  padding: 12px 0;
}
.cmplex-filter .f-footer {
  text-align: center;
}
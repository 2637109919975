<template>
<el-form ref="form" v-if="user" :model="user" label-width="80px" size="mini" :inline="true">
    <h3>{{msg('用户基本信息')}}</h3>

    <el-row>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('姓名:')">
                <span>{{user.fullname}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('用户名:')">
                <span>{{user.username}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('状态:')">
                <span>{{this.dicts.userEditStatuses.find(d => d.value === user.status)?this.dicts.userEditStatuses.find(d => d.value === user.status).label:''}}</span>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('角色:')">
                <span>{{user.roleLabel}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('组织:')">
                <span>{{user.deptLabel}}</span>
            </el-form-item>
        </el-col>
        <!-- <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('组织路径:')">
                <span>{{user.deptLabelPath}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('推广码:')">
                <span>{{user.saleCode}}</span>
            </el-form-item>
        </el-col> -->
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('会员类型:')">
                <span>{{this.dicts.vipTypes.find(d => d.value === user.vip)?this.dicts.vipTypes.find(d => d.value === user.vip).label:''}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('到期时间:')" >
                <span>{{user.vipExpire}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('注册日期:')" >
                <span>{{user.created}}</span>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('上次登录时间:')" label-width="120px">
                <span>{{user.prevLoginDate}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('上次登录IP:')" label-width="120px">
                <span>{{user.prevLoginIp}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('面单标识:')" label-width="120px">
                <span>{{user.pageTag}}</span>
            </el-form-item>
        </el-col>
    </el-row>
    <!-- <el-row>
        <el-col :xs="24">
            <el-form-item :label="msg('token'+': ')" label-width="120px">
                <span>{{ visiable ? (token? token: '未设置')  : '***************************************'}}</span>
                <el-tooltip class="item" effect="dark" :content="msg('查看token')" placement="top-start">
                    <i style="cursor: pointer; display: inline-block; margin-left: 15px; margin-right: 10px;"  @click="viewToken" class="el-icon-view"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" :content="msg('更新token')" placement="top-start">
                    <i style="cursor: pointer;" @click="updateToken" class="el-icon-refresh"></i>
                </el-tooltip>
            </el-form-item>
        </el-col>
    </el-row> -->

    <h3>{{msg('用户扩展资料')}}</h3>
    <el-row v-if="user.profile">
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('其他姓名:')">
                <span>{{user.profile.otherName}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('出生年月:')">
                <span>{{user.profile.birthday}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('性别:')">
                <span>{{this.dicts.genders.find(d => d.value === user.profile.gender)?this.dicts.genders.find(d => d.value === user.profile.gender).label:''}}</span>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="user.profile">
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('应急电话:')" >
                <span>{{user.profile.tel}}</span>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :lg="8">
            <el-form-item :label="msg('备注:')" >
                <span>{{user.profile.remark}}</span>
            </el-form-item>
        </el-col>
    </el-row>

    <span v-if="user.contacts && user.contacts.length>0">
        <h3>{{msg('联系方式')}}</h3>
        <span  v-for="(contact,i) in user.contacts" v-bind:key="i">
            <div class="detail-span">
                <el-row>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('名称:')">
                            <span>{{contact.label}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('联系方式1:')">
                            <span>{{contact.xvalue}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('联系方式2:')">
                            <span>{{contact.xvalue1}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('是否默认:')">
                            <span>{{contact.defaulted==1?'是':'否'}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </span>
    </span>

    <span v-if="user.addresses && user.addresses.length>0">
        <h3>{{msg('联系地址')}}</h3>
        <span v-for="(addr,i) in user.addresses" v-bind:key="i" >
            <div class="detail-span">
                <el-row>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('联系人:')">
                            <span>{{addr.name}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('电话:')">
                            <span>{{addr.tel}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('省份:')">
                            <span>{{addr.province}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('市:')">
                            <span>{{addr.city}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('区县:')">
                            <span>{{addr.district}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="12">
                        <el-form-item :label="msg('详细地址:')">
                            <span>{{addr.detail}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <el-form-item :label="msg('是否默认:')">
                            <span>{{addr.defaulted==1?'是':'否'}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </span>
    </span>

    <span v-if="user.banks && user.banks.length>0">
        <h3>{{msg('银行卡信息')}}</h3>
        <span v-for="(bank,i) in user.banks" v-bind:key="i">
            <div class="detail-span">
                <el-row>
                    <el-col :xs="24" :sm="8" :lg="8">
                        <el-form-item :label="msg('开户姓名:')">
                            <span>{{bank.bankUser}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="8">
                        <el-form-item :label="msg('银行卡号:')">
                            <span>{{bank.bankCode}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="8">
                        <el-form-item :label="msg('是否默认:')">
                            <span>{{bank.defaulted==1?'是':'否'}}</span>
                        </el-form-item>
                    </el-col>
                 </el-row>
                <el-row>
                    <el-col :xs="24" :sm="8" :lg="10">
                        <el-form-item :label="msg('银行名称:')">
                            <span>{{bank.bankName}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :lg="14">
                        <el-form-item :label="msg('开户网点:')">
                            <span>{{bank.bankAddress}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </span>
    </span>


    <div style="text-align:center; margin-top: 15px;">
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
    </div>

</el-form> 
</template>

<script>

export default {
    name: 'userEdit',
    data(){
        return{
            user: null,
            visiable: false,
            token: null,
        }
    },
    mounted: function(){
    },
    props:{
    },
    methods:{
        initForm(userId, type){ //初始化表单数据
            this.user = {};
            if(userId){
                this.getUser(userId);
            }
        },
        getUser(userId){ //获取用户数据
            this.$axios. get(this.$kit.api.user.get+"?id="+userId)
                .then((result) => {
                    if(result.status){
                        var u = result.data;
                        delete u.created;
                        delete u.roleDataPath;
                        delete u.userPath;
                        delete u.parentId;
                        u['password'] = null;
                        u['repassword'] = null;
                        this.user = u;
                        this.token =  u.token;
                        this.role = this.user.role || {id: null, label:null}
                    }else{
                        this.$message(result.msg);
                    }
                });
        },
        updateToken(){
            var _this = this; 
            this.$confirm(this.msg('更新以后，旧的token将会失效，请谨慎操作'), '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    
                _this.$axios. get(this.$kit.api.user.updateToken)
                    .then((result) => {
                        if(result.status){
                            _this.user.token = result.data;
                            _this.token = result.data;
                            _this.visiable = true;
                        }else{
                            _this.$message(result.msg);
                        }
                    });
            });
        },
        viewToken(){
            this.visiable = !this.visiable;
        }
    }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}
.detail-span{
    border: 1px solid #DCDFE6;
    margin-top:0px;
    padding-top:10px
}

@media only screen and (min-width: 768px){
    .el-col-sm-8.spec-cell{
        clear:left;
    }
    .el-col-sm-8.spec-cell1{
        clear:none;
    }
}

@media only screen and (min-width: 1200px){
    .el-col-lg-6.spec-cell {
        clear:none;
    }
    .el-col-lg-6.spec-cell1 {
        clear:left;
    }
}



</style>

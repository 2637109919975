//components/Icon-svg
<template>
  <svg :style="fontNum" class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: 'icon-svg',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    fontSize: {
      type: String,
      required: true
    }
  },
  computed: {
    iconName () {
      return `#${this.iconClass}`
    },
    fontNum() {
      return `font-size:${this.fontSize}`
    }
  }
}
</script>

<style>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  margin: 4px 4px;
}
</style>

import { render, staticRenderFns } from "./WarnMessage.vue?vue&type=template&id=6c996faa&scoped=true"
import script from "./WarnMessage.vue?vue&type=script&lang=js"
export * from "./WarnMessage.vue?vue&type=script&lang=js"
import style0 from "./WarnMessage.vue?vue&type=style&index=0&id=6c996faa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c996faa",
  null
  
)

export default component.exports
<template>
  <div id="app">
     <router-view />
  </div>
</template>

<script>

export default {
  name: 'app',
  
}
</script>

<style>
html{
  width:100%; 
  height:100%;
}
body{
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  width:100%; 
  height:100%;
  margin:0px; padding: 0px;
}
#app{
  width:100%; 
  height:100%;
} 

</style>

<template>
  <div class="my-filter-content">
    <div class="my-fc-search">
      <div class="my-fc-search-top">
        <vxe-input v-model="option.data.sVal" placeholder="搜索" suffix-icon="fa fa-search"></vxe-input>
      </div>
      <div class="my-fc-search-content">
        <template v-if="valList.length">
          <div style="padding: 10px; border: 1px solid #dddddd; margin:15px 0px; max-height:400px; overflow:auto; ">
            <el-checkbox style="margin: 5px 0px; border-bottom:1px solid #dddddd; display: block; padding-bottom:10px;" 
              v-model="isAll" 
              @change="changeAllEvent">全选</el-checkbox>
            
            <el-checkbox style="display:block; margin: 5px 0px;" v-for="(item, sIndex) in valList" 
              v-model="item.checked" :key="sIndex" :label="item.value">{{ item.label }}</el-checkbox>
          </div>
        </template>
        <template v-else>
          <div class="my-fc-search-empty">无匹配项</div>
        </template>
      </div>
    </div>
    <div class="my-fc-footer">
      <el-button type="primary" size="mini" @click="confirmFilterEvent">确认</el-button>
      <el-button type="plain" size="mini" @click="resetFilterEvent">重置</el-button>
    </div>
  </div>
</template>

<script>
import XEUtils from 'xe-utils'

export default {
  name: 'FilterContent',
  props: {
    params: Object
  },
  data () {
    return {
      size: 'mini',
      isAll: false,
      option: null,
      colValList: [],
      valList: [],
      my: null,
    }
  },
  created () {
    this.load()
  },
  methods: {
    load () {
      const { $table, column } = this.params;
      const { fullData } = $table.getTableData()
      const option = column.filters[0]
      const { vals, my} = option.data;
      //my是我自定义的字段
      let labels = [];
      
      if(my){
        this.my = my;
      }

      if(my == "t_stock" && column.property == 'status'){
        labels = this.dicts.stockStatus;
      }else if(my == "t_stock" && column.property == 'orderOrigin'){
        labels = this.dicts.orderOrigin;
      }else if(my == "t_stock" && column.property == 'orderPlatform'){
        labels = this.dicts.orderPlatforms;
      }else if(my == "t_stock" && column.property == 'expAllowPrint'){
        labels = this.dicts.stockExpAllowPrint;
      }else if(my == "t_stock" && column.property == 'expPageStatus'){
        labels = this.dicts.stockExpPageStatus;
      }else if(my&&my.startsWith("dict:")){
        var dictType = my.substr(5);
        labels = this.dicts[dictType];
      }

      let _this = this;
      const colValList = Object.keys(XEUtils.groupBy(fullData, column.property)).map(val => {
        return {
          checked: vals.includes(val),
          label: val,
          value: val
        }
      });
      if(labels && labels.length>0){
        colValList.forEach(c => {
          let label = labels.find(b => b.value == c.value);
          if(label){
            c.label = label.label;
          }else{
            c.label = '';
          }
        });

      }

      this.option = option
      this.colValList = colValList
      this.valList = colValList
    },
    searchEvent () {
      const { option, colValList } = this
      this.valList = option.data.sVal ? colValList.filter(item => item.value.indexOf(option.data.sVal) > -1) : colValList
    },
    changeAllEvent () {
      const { isAll } = this
      this.valList.forEach(item => {
        item.checked = isAll
      })
    },
    confirmFilterEvent (evnt) {
      const { params, option, valList } = this
      const { data } = option
      const { $panel } = params
      data.vals = valList.filter(item => item.checked).map(item => item.value)
      $panel.changeOption(evnt, true, option)
      $panel.confirmFilter()
    },
    resetFilterEvent () {
      // debugger;
      const { $panel } = this.params
      $panel.resetFilter()
    }
  }
}
</script>

<style>
.my-filter-content {
  user-select: none;
}
.my-filter-content .my-fc-search .my-fc-search-top {
  position: relative;
  padding: 5px 0;
}
.my-filter-content .my-fc-search .my-fc-search-top > input {
  border: 1px solid #ABABAB;
  padding: 0 20px 0 2px;
  width: 200px;
  height: 22px;
  line-height: 22px;
}
.my-filter-content .my-fc-search .my-fc-search-content {
  padding: 2px 10px;
}
.my-filter-content .my-fc-search-empty {
  text-align: center;
  padding: 20px 0;
}
.my-filter-content .my-fc-search-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.my-filter-content .my-fc-search-list-body {
  overflow: auto;
  height: 120px;
}
.my-filter-content .my-fc-search-list .my-fc-search-item {
  padding: 2px 0;
  display: block;
}
.my-filter-content .my-fc-footer {
  text-align: right;
  padding-top: 10px;
}

</style>